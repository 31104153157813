function _defineProperty(a,b,c){return b=_toPropertyKey(b),b in a?Object.defineProperty(a,b,{value:c,enumerable:!0,configurable:!0,writable:!0}):a[b]=c,a}function _toPropertyKey(a){var b=_toPrimitive(a,"string");return"symbol"==typeof b?b:b+""}function _toPrimitive(a,b){if("object"!=typeof a||null===a)return a;var c=a[Symbol.toPrimitive];if(c!==void 0){var d=c.call(a,b||"default");if("object"!=typeof d)return d;throw new TypeError("@@toPrimitive must return a primitive value.")}return("string"===b?String:Number)(a)}import BaseService from"./BaseService";import consola from"consola";import axios from"axios";// Types
import{moduled}from"./ApiService";class CartService extends BaseService{constructor(a){super("cart",{cart:null,guestUser:null},a),_defineProperty(this,"api",void 0),_defineProperty(this,"ctx",void 0),this.ctx=a,this.api=a.$api}get session(){const{protectCode:a,id:b}=this.storage.cart||{};return a&&b?{protectCode:a,id:b}:null}get items(){return this.storage.cart?this.storage.cart.items:[]}get guestUser(){return this.storage.guestUser||null}get isEProductCart(){var a;return!!(null!==(a=this.storage.cart)&&void 0!==a&&a.digitalOnly)}/**
   * Checks if cart id and protect code are in cookies and fetches cart
   * with that data if it exists.
   */async checkSessionOnInit(){const a=this.ctx.$getCookie("cartId"),b=this.ctx.$getCookie("protectCode");b&&a&&(await this.fetchCart(a,b))}/**
   * Fetch cart for existing session and write cart id and protect code into cookies.
   * @param id
   * @param protectCode
   */async fetchCart(a,b){if(a&&b){// todo: consider adding currencyId
const c=await this.api.ecommerce.checkout.cart.getCart(a,b);if(!c.errored)return this.setState("cart",c.data),this.setCartCookies(),c;const d=[400,409].includes(c.statusCode);return d&&(consola.error("Clearing cart session."),this.clearSession()),c}}/**
   * Clears cart storage and removes cart id and protect code cookies.
   */clearSession(){this.setState("cart",null),this.ctx.$deleteCookie("cartId"),this.ctx.$deleteCookie("protectCode")}/**
   * Creates new cart session and writes cart id and protect code into cookies.
   */async newSession(){const a=await this.api.ecommerce.checkout.cart.getNewCartSession();return a.errored||this.setState("cart",a.data),this.setCartCookies(),a}/**
   * If cart session exists, writes cart id and protect code into cookies.
   */setCartCookies(){if(this.session){const a={maxAge:31536000,path:"/"};this.ctx.$setCookie("cartId",this.session.id,a),this.ctx.$setCookie("protectCode",this.session.protectCode,a)}}/**
   * After logout, cart id and protect code are deleted from cookies. After logging in, we need to fetch cart id and protect code, fetch cart with them and once again write cart id and protect code into cookies.
   */async fetchAbandonedSession(){// Commented code should actually be entire method, but since getAbandoned return 404 if there was no cart session(like when new user logs in)
// we dont want to display error message so we cant use our apiService, which is completely retarded
// const data = await this.api.ecommerce.checkout.cart.getAbandoned()
// !data.errored && (await this.fetchCart(data.data.id, data.data.protectCode))
try{const{headers:a,baseURL:b}=this.ctx.$axios.defaults,c=(await axios.request({baseURL:b,url:moduled("ecommerce","checkout/cart/getAbandoned","v2"),method:"post",headers:a})).data.data;await this.fetchCart(c.id,c.protectCode)}catch(a){consola.error("$cart.fetchAbandonedSession failed (probably because new user with no cart session logged in): ",a.message)}}/**
   * Find product in cart items
   * @param product target product object
   */findProduct(a){return this.items.find(b=>b.productId===a.id)}/**
   * Find cart item by id
   * @param itemId target item id
   */findItem(a){return this.items.find(b=>b.itemId===a)}/**
   * Adds product to current cart session. If session doesn't exist,
   * it creates it before adding product.
   * */async addItem(a,b=1,c={},d){var e;if(!this.session){const e=await this.newSession();return e.errored?e:await this.addItem(a,b,c,d)}const f=a=>"number"!=typeof a&&"customOptions"in a,{addItem:g}=this.api.ecommerce.checkout.cart,h={cartId:this.session.id,protectCode:this.session.protectCode,currencyId:null===(e=this.ctx.$application.currency)||void 0===e?void 0:e.id,productId:"number"==typeof a?a:a.id,qty:+b,options:c,reservation:d// currencyId: rootState.application.selectedCurrencyId
};// TODO: this is temporarily hardcoded to always take the first option and value for Celero, TBD as customisable options a la product configuration
if(f(a)&&!c.customPriceOption){var i;if("subscription"===(null===(i=a.customOptions[0])||void 0===i?void 0:i.option_title)){var j,k,l;const b=null===(j=a.customOptions[0])||void 0===j||null===(k=j.custom_option_values[0])||void 0===k?void 0:k.sku,c=null===(l=a.customOptions[0])||void 0===l?void 0:l.option_title;b&&c&&(h.options={...h.options,customPriceOption:{customOptionTitle:c,customOptionValue:b}})}}else if(c.customPriceOption)h.options={...h.options,customPriceOption:c.customPriceOption};else{const{storeID:d}=this.ctx.$application.store||{};if(d&&"number"!=typeof a){const e=await this.api.product.web.get({id:a.id,storeId:d// You have to be fucking kidding me
});return e.errored?e:await this.addItem(e.data,b,c)}}// // TODO: this is temporary horrible hotfix, configurabile products are not sent in correct PARENT_ID:CHILD_ID format until backend solves a problem with returning format inside BundleProduct type and Bundle type.
// // TODO: Delete this shit ASAP.
// // should be using if(product.format === ProductFormat.Bundle) when that stuff gets added
// if (
//   typeof product !== 'number' &&
//   //@ts-ignore
//   product.bundles &&
//   //@ts-ignore
//   Array.isArray(product.bundles) &&
//   //@ts-ignore
//   product.bundles.length
// ) {
//   //@ts-ignore
//   const bundleOptions = product.bundles.map((productFromBundle) => {
//     if (productFromBundle.id) {
//       // Bundle type, from product/checkBundles route
//       return productFromBundle.id
//     } else if (productFromBundle.product && productFromBundle.product.id) {
//       // ProductRecord type, from product/web/filter route (can also be format === 'bundle')
//       return productFromBundle.product.id
//     } else {
//       // Product type, from product/web/get route (can also be format === 'bundle')
//       return productFromBundle.product
//     }
//   })
//   //@ts-ignore
//   body.options = {
//     ...body.options,
//     bundleOptions,
//   }
// }
const m=await g(h);return m.errored||this.setState("cart",m.data),m}/**
   * Update item in cart.
   * @param itemId id of cart item, NOT product id
   * @param quantity new quantity to set
   * @param options personalisation, subscription etc.
   * @param cancelToken used for canceling requests (advanced usage)
   * @param setState whether to override the whole cart object with the new one from response of this call
   */async updateItem(a,b=1,c,d,e=!0){var f;if(!this.session)throw new Error("Session doesn't exist");const g={cartId:this.session.id,protectCode:this.session.protectCode,currencyId:null===(f=this.ctx.$application.currency)||void 0===f?void 0:f.id,itemId:a,qty:+b,options:c// currencyId: rootState.application.selectedCurrencyId
},h=await this.api.ecommerce.checkout.cart.updateItem({...g,cancelToken:d});if(e)h.errored||this.setState("cart",h.data);else{var i;const a=null===(i=this.storage.cart)||void 0===i?void 0:i.prices;a&&!h.errored&&Object.assign(a,h.data.prices)}// TODO: handle this stupid thing differently
// items should be separate and updated when needed, not the whole cart always
return h.errored||this.items.splice(0,this.items.length,...h.data.items),h}/**
   * Set cart item state
   * @param product product by which cart item will be searched
   * @param options new state to commit - quantity
   * @param customOptions persionalizations and stuff
   */async setItem(a,b,c){const d=this.findProduct(a);return d?await this.updateItem(d.itemId,b.quantity,c):await this.addItem(a,b.quantity,c)}async deleteItem(a){if(!this.session)throw new Error("Session doesn't exist");const b={cartId:this.session.id,protectCode:this.session.protectCode,itemId:a// currencyId: rootState.application.selectedCurrencyId
},c=await this.api.ecommerce.checkout.cart.deleteItem(b);return c.errored||this.setState("cart",c.data),c}async clearItems(){if(!this.session||!this.items.length)return void consola.error("Session does not exist or cart is already empty");const a={cartId:this.session.id,protectCode:this.session.protectCode},b=await this.api.ecommerce.checkout.cart.clearCart(a);b.errored||this.setState("cart",b.data)}async updateGuestUser(a){if(!this.session)throw new Error("Session doesn't exist");const b=await this.api.user.web.register(a);return b.errored||(this.setState("guestUser",b.data),this.ctx.$setCookie("guestUser",b.data.email)),b}async updateDeliveryMethod(a,b){var c,d;if(!this.session)throw new Error("Session doesn't exist");const e=(null===(c=this.ctx.$auth.user)||void 0===c?void 0:c.id)||(null===(d=this.guestUser)||void 0===d?void 0:d.id);if(!e)throw new Error("No active user");const f=await this.api.ecommerce.checkout.delivery.saveDeliveryMethod({userId:e,cartId:this.session.id,protectCode:this.session.protectCode,deliveryMethodId:a,branchId:b});return f.errored||this.setState("cart",f.data),f}async applyCoupon(a){if(!this.session)throw new Error("Session doesn't exist");const b=await this.api.ecommerce.checkout.cart.applyCoupon({cartId:this.session.id,protectCode:this.session.protectCode,couponCode:a});return b.errored||this.setState("cart",b.data),b}async removeCoupon(){if(!this.session)throw new Error("Session doesn't exist");const a=await this.api.ecommerce.checkout.cart.removeCoupon({cartId:this.session.id,protectCode:this.session.protectCode});return a.errored||this.setState("cart",a.data),a}async applyMemberBarcode(a){if(!this.session)throw new Error("Session doesn't exist");const b=await this.api.ecommerce.checkout.cart.applyMemberBarcode({cartId:this.session.id,protectCode:this.session.protectCode,barcode:a});return b.errored||this.setState("cart",b.data),b}}export default CartService;