<template>
  <div class="view-container">
    <controller-error
      v-slot="{ message, statusCode }"
      class="w-full text-center flex flex-col items-center justify-center mb-20 sm:mb-32 xl:mb-0"
    >
      <p
        class="uppercase font-pragmatica-extended text-2xl font-semibold mb-12 sm:mb-24 sm:text-5xl"
      >
        Error {{ statusCode }}: {{ message }}.
      </p>

      <cms-link to="/" class="mb-12 w-1/2 xl:w-1/3 md:mb-24">
        <cms-image
          id="hsc-navbar-logo"
          :link="$siteLogo"
          class="transitioned w-full h-full"
        />
      </cms-link>

      <cms-link
        to="/"
        class="text-xl text-lightGrayishBlue leading-none flex items-center justify-center transitioned hover:text-pureYellow sm:text-3xl"
        ><fa
          :icon="icons.basic.leftArrow"
          class="mr-3 text-pureYellow transitioned"
        /><span> {{ $t('hsc.cart.backToHomePage') }}</span></cms-link
      >
    </controller-error>
  </div>
</template>
<script>
import FaIcons from '@/mixins/FaIcons'
import ControllerError from 'Controllers/ControllerError'
export default {
  components: {
    ControllerError,
  },
  mixins: [FaIcons],

  // test pipeline
}
</script>
<style scoped>
a:hover svg {
  @apply text-pureYellow transform scale-110;
}
</style>
