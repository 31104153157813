var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full text-1.4rem flex items-center justify-center space-x-1 md:space-x-2"},[_c('button',{staticClass:"navigation-button transitioned",class:[{ 'disabled-btn': _vm.disabled || !(_vm.options.page > 2) }],on:{"click":_vm.firstPage}},[_c('fa',{attrs:{"icon":_vm.icons.pagination.firstPage}})],1),_vm._v(" "),_c('button',{staticClass:"navigation-button transitioned",class:[{ 'disabled-btn': _vm.disabled || !(_vm.options.page > 1) }],on:{"click":_vm.previousPage}},[_c('fa',{attrs:{"icon":_vm.icons.pagination.previousPage}})],1),_vm._v(" "),_c('button',{staticClass:"transitioned hover:shadow-lg",class:[
      _vm.options.page === _vm.leftCellNumber
        ? 'pagination-button-active'
        : 'pagination-button-inactive',
      { 'disabled-btn': _vm.disabled },
    ],on:{"click":function($event){return _vm.handlePageClick(_vm.leftCellNumber)}}},[_vm._v("\n    "+_vm._s(_vm.leftCellNumber)+"\n  ")]),_vm._v(" "),_c('transition',{attrs:{"name":"fade-200ms"}},[(_vm.middleCellNumber)?_c('button',{staticClass:"transitioned hover:shadow-lg",class:[
        _vm.options.page === _vm.middleCellNumber
          ? 'pagination-button-active'
          : 'pagination-button-inactive',
        { 'disabled-btn': _vm.disabled },
      ],on:{"click":function($event){return _vm.handlePageClick(_vm.middleCellNumber)}}},[_vm._v("\n      "+_vm._s(_vm.middleCellNumber)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade-200ms"}},[(_vm.rightCellNumber)?_c('button',{staticClass:"transitioned hover:shadow-lg",class:[
        _vm.options.page === _vm.rightCellNumber
          ? 'pagination-button-active'
          : 'pagination-button-inactive',
        { 'disabled-btn': _vm.disabled },
      ],on:{"click":function($event){return _vm.handlePageClick(_vm.rightCellNumber)}}},[_vm._v("\n      "+_vm._s(_vm.rightCellNumber)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('button',{staticClass:"navigation-button transitioned",class:[
      {
        'disabled-btn':
          _vm.disabled || !(_vm.options.page < _vm.paginationState.totalPages),
      },
    ],on:{"click":_vm.nextPage}},[_c('fa',{attrs:{"icon":_vm.icons.pagination.nextPage}})],1),_vm._v(" "),_c('button',{staticClass:"navigation-button transitioned",class:[
      {
        'disabled-btn':
          _vm.disabled || !(_vm.options.page < _vm.paginationState.totalPages - 1),
      },
    ],on:{"click":_vm.lastPage}},[_c('fa',{attrs:{"icon":_vm.icons.pagination.lastPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }