<template>
  <!--Actual button -->
  <button
    :type="isSubmit ? 'submit' : 'button'"
    class="border-2 relative px-3 z-0 box-border flex flex-shrink-0 items-center justify-center rounded-full text-lg font-bold tracking-wider transition-opacity duration-500 ease-in-out font-pragmatica-extended min-w-11.5rem h-12 md:min-w-17rem md:h-16 md:text-2xl focus:outline-none md:tracking-widest"
    :class="[
      isTransparent ? 'bg-transparent' : `bg-${primaryColorName}`,
      `border-${primaryColorName} text-${textColorName}`,
      { 'opacity-50 cursor-not-allowed': disabled },
    ]"
    :disabled="disabled"
    @mouseenter="showBorders"
    @mouseleave="hideBorders"
    @click.left="$emit('clicked')"
  >
    <slot></slot>

    <!-- Inner border -->
    <div
      class="child-border-2 border-2 z-neg1 pointer-events-none absolute hidden flex-shrink-0 items-center justify-center rounded-full border-transparent ease-in-out transition-colors duration-300 md:flex md:min-w-18rem md:h-20"
      :class="{ [`border-${primaryColorName}`]: isHovered }"
    >
      <!-- Middle border -->
      <div
        class="child-border-2 border pointer-events-none absolute hidden flex-shrink-0 items-center justify-center rounded-full border-transparent transition-colors ease-in-out duration-200 delay-100 md:flex md:min-w-19rem md:h-24"
        :class="{ [`border-${primaryColorName}`]: isHovered }"
      >
        <!-- Outter border -->
        <div
          class="child-border-1 border pointer-events-none absolute hidden flex-shrink-0 rounded-full border-transparent transition-colors ease-in-out duration-100 delay-200 md:block md:min-w-20rem md:h-7rem"
          :class="{ [`border-${primaryColorName}-50`]: isHovered }"
        />
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'MultipleBorderedButton',
  // Use custom color names from tailwind.config.js to set primaryColorName prop
  // if you want to insert new color:
  // First add it to tailwind.config.js and create 'colorname.50' color version with opacity of 0.5 which will be used for outter border,
  // then use its 100 opacity name as a prop

  props: {
    primaryColorName: {
      type: String,
      default: 'pureYellow',
    },
    textColorName: {
      type: String,
      default: 'darkGrayishViolet',
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isHovered: false,
    }
  },

  watch: {
    disabled() {
      this.hideBorders()
    },
  },
  methods: {
    showBorders() {
      if (this.disabled) {
        return
      }
      this.isHovered = true
    },
    hideBorders() {
      this.isHovered = false
    },
  },
}
</script>
<style scoped>
@media only screen and (min-width: 768px) {
  .child-border-2 {
    width: calc(100% + 14px);
  }
  .child-border-1 {
    width: calc(100% + 12px);
  }
}
.bg-pureYellow {
  color: white !important;
}
</style>
