<template>
  <form
    class="flex flex-col items-center justify-center text-lg sm:flex-row md:text-1.4rem"
    :class="growAfterSmallScreen ? 'sm:flex' : 'sm:inline-flex'"
    @submit.prevent="submitMethod"
  >
    <input
      :value="value"
      type="text"
      class="h-12 w-full flex-shrink text-center tracking-0.14rem placeholder-lightGrayishBlue sm:h-16 sm:px-3 sm:w-auto sm:text-left focus:outline-none"
      :class="[
        `bg-${inputBgColor}`,
        maxInputWidthClass ? `sm:${maxInputWidthClass}` : '',
        {
          'border-t border-l border-r sm:border-b sm:border-r-0 border-lightGrayishBlue':
            applyBorders,
        },
      ]"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
    />

    <button
      type="submit"
      :disabled="!value"
      :class="[
        { 'opacity-80 cursor-not-allowed': !value },
        {
          'border-b border-l border-r sm:border-b sm:border-l-0 border-darkGrayishViolet':
            applyBorders,
        },
      ]"
      class="h-12 w-full sm:w-auto px-4 flex-grow flex-shrink-0 bg-darkGrayishViolet tracking-0.14rem uppercase text-white font-pragmatica-extended font-extrabold transition-opacity duration-500 ease-in-out sm:h-16 sm:px-8 focus:outline-none"
    >
      {{ btnText }}
    </button>
  </form>
</template>
<script>
export default {
  props: {
    submitMethod: {
      type: Function,
      default() {
        return null
      },
    },
    value: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputBgColor: {
      type: String,
      default: 'white',
    },
    growAfterSmallScreen: {
      type: Boolean,
      default: false,
    },
    maxInputWidthClass: {
      type: String,
      default: '',
    },
    applyBorders: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
