<template>
  <div class="view-container bg-login-background bg-left bg-cover">
    <div class="content-container">
      <h1
        class="main-title mb-12 uppercase overflow-hidden font-extrabold leading-tight font-pragmatica-extended xl:mb-7rem"
      >
        {{ $t('hsc.register.title') }}
      </h1>
      <div
        class="flex flex-col items-start justify-between xl:flex-row xl:items-start"
      >
        <h2
          class="main-subtitle mb-12 overflow-hidden leading-tight font-pragmatica-extended md:mb-16 xl:mt-8 xl:w-2/5 xl:mb-0"
        >
          {{ $t('hsc.register.subtitle') }}
        </h2>
        <controller-register
          v-slot="{ form, submit, status, generateErrorMessage, consents }"
          :auto-login="true"
          class="self-center w-11/12 px-5 pt-5 pb-6 bg-whiteSmoke rounded-lg sm:w-4/6 md:px-8 md:pt-8 md:pb-10 lg:w-3/5 xl:w-1/2"
        >
          <form @submit.prevent="submit">
            <section class="mb-5">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.firstName') }}*
                <input
                  v-model.trim="form.firstName"
                  type="text"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.firstName')"
                />
              </label>
              <p
                v-show="generateErrorMessage('form.firstName')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.firstName') }}
              </p>
            </section>
            <section class="mb-5">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.lastName') }}*
                <input
                  v-model.trim="form.lastName"
                  type="text"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.lastName')"
                />
              </label>
              <p
                v-show="generateErrorMessage('form.lastName')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.lastName') }}
              </p>
            </section>
            <section class="mb-5">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.email') }}*
                <input
                  v-model.trim="form.email"
                  type="email"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.email')"
              /></label>
              <p v-show="generateErrorMessage('form.email')" class="error-msg">
                {{ generateErrorMessage('form.email') }}
              </p>
            </section>
            <section class="mb-5">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.password') }}*
                <div class="relative">
                  <input
                    v-model.trim="form.password"
                    :type="
                      passwordOptions.passwordVisible ? 'text' : 'password'
                    "
                    class="form-text password-input-padding form-input placeholder-lightGrayishBlue focus:outline-none"
                    :placeholder="$t('hsc.form.inputs.password')"
                  />
                  <font-awesome-icon
                    class="cursor-pointer password-eye-icon transitioned"
                    size="l"
                    :icon="`fa-solid ${
                      !passwordOptions.passwordVisible
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    } `"
                    @click.stop="togglePasswordVisibility"
                  />
                </div>
              </label>
              <p
                v-show="generateErrorMessage('form.password')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.password') }}
              </p>
            </section>
            <section class="mb-5">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.repeatPassword') }}*
                <div class="relative">
                  <input
                    v-model.trim="form.confirmedPassword"
                    :type="
                      passwordOptions.passwordRepeatedVisible
                        ? 'text'
                        : 'password'
                    "
                    class="form-text password-input-padding form-input placeholder-lightGrayishBlue focus:outline-none"
                    :placeholder="$t('hsc.form.inputs.repeatPassword')"
                  />
                  <font-awesome-icon
                    class="cursor-pointer password-eye-icon transitioned"
                    size="l"
                    :icon="`fa-solid ${
                      !passwordOptions.passwordRepeatedVisible
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    } `"
                    @click.stop="togglePasswordRepeatedVisibility"
                  />
                </div>
              </label>
              <p
                v-show="generateErrorMessage('form.confirmedPassword')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.confirmedPassword') }}
              </p>
            </section>
            <section
              class="mb-10 form-text grid grid-cols-1 gap-6 md:mb-4.5rem"
            >
              <div
                v-for="consent in consents"
                :key="consent.uniqueNumber"
                class="flex items-center"
              >
                <input
                  v-model="form.acceptedConsents"
                  type="checkbox"
                  :value="consent.uniqueNumber"
                  class="mr-2 md:mr-4 md:w-8 md:h-8"
                />
                <div
                  class="leading-tight inline"
                  v-html="consent.displayName"
                />
              </div>
            </section>
            <section>
              <multiple-bordered-button
                primary-color-name="darkGrayishViolet"
                text-color-name="white"
                is-submit
                :disabled="
                  status.pending ||
                  form.acceptedConsents.length !== consents.length
                "
              >
                <span class="btn-text">{{
                  $t('hsc.form.actions.register')
                }}</span>
              </multiple-bordered-button>
            </section>
          </form>
        </controller-register>
      </div>
    </div>
  </div>
</template>

<script>
import ControllerRegister from 'Controllers/user/ControllerRegister'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import FaIcons from '@/mixins/FaIcons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
library.add(faEye, faEyeSlash)
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FontAwesomeIcon,
    ControllerRegister,
    MultipleBorderedButton,
  },
  mixins: [FaIcons],
  data() {
    return {
      confirmedPassword: '',
      passwordOptions: {
        passwordVisible: false,
        passwordRepeatedVisible: false,
      },
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordOptions.passwordVisible =
        !this.passwordOptions.passwordVisible
    },
    togglePasswordRepeatedVisibility() {
      this.passwordOptions.passwordRepeatedVisible =
        !this.passwordOptions.passwordRepeatedVisible
    },
  },
}
</script>
