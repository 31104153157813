import moment from 'moment'

export default {
  methods: {
    getDate(date) {
      moment.locale(this.$application.language.code2)
      const formattedDate = moment(date).format('MMMM Do, YYYY')
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    },
  },
}
