<template>
  <div class="h-12 flex items-center justify-center text-1.4rem md:h-16">
    <button class="custom-input-button transitioned" @click="decrementValue()">
      -
    </button>

    <input
      ref="customInput"
      type="number"
      class="w-12 mx-4 h-full text-center bg-darkGrayishViolet text-white md:w-16 focus:outline-none"
      min="1"
      max="999"
      disabled
      :value="value"
      @keydown.prevent
    />
    <button class="custom-input-button transitioned" @click="incrementValue()">
      +
    </button>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  // We cannot only listen for input event on input element because after clicking on buttons +/-, .stepUp() and stepDown() wont trigger it
  // That is why emitChange() has been called after calling .stepUp or stepDown()
  methods: {
    decrementValue: _.debounce(function () {
      this.$refs.customInput.stepDown()
      this.emitChange()
    }, 500),
    incrementValue: _.debounce(function () {
      this.$refs.customInput.stepUp()
      this.emitChange()
    }, 500),
    emitChange() {
      this.$emit('input', this.$refs.customInput.value)
    },
  },
}
</script>
<style scoped>
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.custom-input-button {
  @apply w-12 h-full bg-white text-lightGrayishBlue border border-lightGrayishBlue;
}

.custom-input-button:focus {
  @apply outline-none;
}

.custom-input-button:hover {
  @apply border-pureYellow text-pureYellow;
}

@media only screen and (min-width: 768px) {
  .custom-input-button {
    @apply w-16;
  }
}
</style>
