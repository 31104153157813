<template>
  <div
    v-click-outside="onClickOutside"
    class="fixed z-50 bottom-0 left-50% transform -translate-x-1/2 pb-2 rounded-lg grid grid-cols-4x1fr gap-3 sm:grid-cols-8x1fr"
  >
    <share-network
      v-for="network in networks"
      :key="network.slug"
      :network="network.slug"
      class="w-4.5rem h-4.5rem text-white bg-darkGrayishViolet-85 flex flex-shrink-0 items-center justify-center text-5xl rounded-lg transitioned transform hover:bg-pureYellow hover:text-darkGrayishViolet hover:-translate-y-3 hover:scale-125"
      :url="`${baseUrl}/${endpoint}`"
      :title="title"
      :description="description"
      :quote="quote"
      :hashtags="hashtags"
    >
      <fa :icon="icons.social[network.slug]" />
    </share-network>
  </div>
</template>
<script>
import FaIcons from '@/mixins/FaIcons'
import vClickOutside from 'v-click-outside'
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [FaIcons],
  props: {
    endpoint: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    quote: {
      type: String,
      default: '',
    },
    hashtags: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      baseUrl: window.location.origin,
      networks: [
        {
          slug: 'facebook',
          name: 'Facebook',
          color: '#1877f2',
        },
        {
          slug: 'reddit',
          name: 'Reddit',
          color: '#ff4500',
        },
        {
          slug: 'skype',
          name: 'Skype',
          color: '#00aff0',
        },
        {
          slug: 'twitter',
          name: 'Twitter',
          color: '#1da1f2',
        },
        {
          slug: 'messenger',
          name: 'Messenger',
          color: '#0084ff',
        },

        {
          slug: 'whatsapp',
          name: 'Whatsapp',
          color: '#25d366',
        },
        {
          slug: 'sms',
          name: 'SMS',
          color: '#333333',
        },
        {
          slug: 'email',
          name: 'Email',
          color: '#333333',
        },
      ],
    }
  },
  methods: {
    onClickOutside() {
      this.$emit('closeSocialShare')
    },
  },
}
</script>
<style scoped>
div:hover svg {
  @apply scale-110;
}
</style>
