// Types
import mixins from"vue-typed-mixins";// Mixins
import ControllerBase from"../../mixins/ControllerBase";import consola from"consola";import VuelidateHelper from"../../mixins/VuelidateHelper";const AXIOS_CANCEL_MESSAGE="canceled";//Utils
import{required}from"vuelidate/lib/validators";const ControllerCart=mixins(ControllerBase,VuelidateHelper).extend({name:"ControllerCart",data(){return{cancelUpdate:null,_itemsToUpdate:{},form:{coupon:"",memberCardBarcode:""}}},validations:{form:{coupon:{required}}},mounted(){this.applyUsersMembershipCardBarcode()},computed:{cartItems(){return this.$cart.items.map((a,b)=>{const c={...a};return Object.defineProperty(c,"_qty",{value:a.qty,enumerable:!1,writable:!0}),Object.defineProperty(c,"qty",{get(){return this._qty},set:d=>{const e=+(null!==d&&void 0!==d?d:0);this.$cart.items.splice(b,1,{...c,qty:e}),this.initCartItemUpdate(a,e)}}),c})},priceSummary(){var a;return(null===(a=this.$cart.storage.cart)||void 0===a?void 0:a.prices.converted)||{cartTotal:0,coupon:0,delivery:0,itemsTotal:0,loyalty:0,orderTotal:0,tax:{cartTotal:0,coupon:0,delivery:0,itemsTotal:0,loyalty:0,orderTotal:0}}}},methods:{applyUsersMembershipCardBarcode(){var a;this.$auth.loggedIn&&null!==(a=this.$auth.user)&&void 0!==a&&a.memberCardBarcode&&(this.form.memberCardBarcode=this.$auth.user.memberCardBarcode)},cancelCartItemUpdate(a){var b;const c=this.$data._itemsToUpdate,d=c[a];// If there is current timeout for this call, cancel it
// If there is api call currently going for this item, cancel it
clearTimeout(null===d||void 0===d?void 0:d.timeoutId),null===d||void 0===d||null===(b=d.cancelTokenSource)||void 0===b?void 0:b.cancel(AXIOS_CANCEL_MESSAGE)},initCartItemUpdate(a,b){const{itemId:c}=a,d=this.$data._itemsToUpdate;this.cancelCartItemUpdate(c);const e=setTimeout(async()=>{const e=await this.updateCartItem(a,b);// If we canceled the call manually, we don't want to remove it
// from list of itemsToUpdate, because it would delete what we just set
// for a new call
e!==AXIOS_CANCEL_MESSAGE&&this.$delete(d,c)},500);this.$set(d,c,{qty:b,timeoutId:e,cancelTokenSource:this.$axios.CancelToken.source()})},async updateCartItem(a,b){const{itemId:c}=a,d=this.$data._itemsToUpdate;try{var e,f,g,h,i,j;const a=await this.$cart.updateItem(c,b,void 0,null===(e=d[c])||void 0===e||null===(f=e.cancelTokenSource)||void 0===f?void 0:f.token,!1);return(// @ts-ignore
!1===(null===a||void 0===a||null===(g=a.data)||void 0===g?void 0:g.coupon)&&null!==(h=this.$cart)&&void 0!==h&&null!==(i=h.storage)&&void 0!==i&&null!==(j=i.cart)&&void 0!==j&&j.coupon&&(await this.removeCoupon()),this.$emit("updatedCartItem"),"")}catch(a){return a.message}},async deleteCartItem(a){const{itemId:b}=a,c=this.$data._itemsToUpdate;this.cancelCartItemUpdate(b),this.$set(c,b,{...c[b],isBeingDeleted:!0});try{const a=await this.$cart.deleteItem(b);a.errored||this.$toast.success(this.$t("core.notifications.itemRemovedFromCart"))}catch(a){this.$toast.error(this.$t("error.removeFromCartFailed"))}finally{this.$delete(c,b),this.$emit("deletedCartItem")}},async applyCoupon(){if(!this.$cart.session)return void consola.error("Cart session not initialised!");if(this.$v.form.$touch(),this.$v.form.$invalid)return;const a=this.$cart.applyCoupon(this.form.coupon),b=await this.callWithStatusUpdate(a);return b.errored||!b.data.coupon?(this.$emit("coupon-error"),void(this.form.coupon&&(await this.removeCoupon()))):void this.$emit("coupon-applied")},async applyMembershipCardBarcode(){var a;if(!this.$auth.loggedIn)return;if(!this.$cart.session)return void consola.error("Cart session not initialised!");const b=this.$getCookie("memberCardBarcode");if(b&&b.toString()===this.form.memberCardBarcode)return;if(!this.form.memberCardBarcode.length)/* this.$toast.error(
           this.$t('core.notifications.emptyMembershipCardBarcode') as string
          ) */return void this.$emit("emtpyMemberCardBarcode");const c=this.$cart.applyMemberBarcode(this.form.memberCardBarcode),d=await this.callWithStatusUpdate(c);if(d.errored)return this.$emit("incorrectMemberCardBarcode"),void this.$toast.error(this.$t("core.notifications.membershipCardBarcodeInvalid"));if(this.$auth.loggedIn&&!(null!==(a=this.$auth.user)&&void 0!==a&&a.memberCardBarcode)){var e;const a=null===(e=this.$auth.user)||void 0===e?void 0:e.email,b=this.form.memberCardBarcode;await this.$api.user.web.update({email:a,memberCardBarcode:b}),await this.$auth.fetchUser()}this.$setCookie("memberCardBarcode",this.form.memberCardBarcode),this.$toast.success(this.$t("core.notifications.memebrshipCardBarcodeApplied")),this.$emit("memberCardBarcodeApplied")},async removeCoupon(){if(!this.$cart.session)return void consola.error("Cart session not initialised!");const a=this.$cart.removeCoupon(),b=await this.callWithStatusUpdate(a,"coupon-removed");this.form.coupon="",this.$v.form.$reset();b.errored}},render(){const{deleteCartItem:a,priceSummary:b,form:c,applyCoupon:d,removeCoupon:e,applyMembershipCardBarcode:f,$v:g,generateErrorMessage:h}=this;return this.renderContainer({items:this.cartItems,deleteCartItem:a,priceSummary:b,form:c,applyCoupon:d,cartState:this.$cart.storage.cart||{},removeCoupon:e,applyMembershipCardBarcode:f,$v:g,generateErrorMessage:h})}});export default ControllerCart;