//Types
import mixins from"vue-typed-mixins";// Enums
import{Page}from"@gauss/cms-shared";import{ProductFormat}from"../../enums/Product";// Utils
import isEmpty from"lodash/isEmpty";import consola from"consola";import{genNavigationItemPath,genTermPath}from"../../utils/cmsLinkHelpers";const isTerm=a=>"string"!=typeof a&&"typeSlug"in a,isFromEditorList=a=>!!a&&"string"!=typeof a&&"formattedPath"in a,hasTarget=a=>!!a&&"string"!=typeof a&&"target"in a,isNavigationItem=a=>"string"!=typeof a&&"externalLink"in a&&"children"in a&&"parent_id"in a;function isCartItem(a){return!!a&&"string"!=typeof a&&"productData"in a}const isCmsEvent=a=>!!a&&"string"!=typeof a&&"startEvent"in a&&"endEvent"in a&&"startEvent"in a&&"eventAttendees"in a,isBundle=a=>!!a&&"string"!=typeof a&&"format"in a&&a.format===ProductFormat.Bundle&&"bundles"in a&&Array.isArray(a.bundles)&&!!a.bundles.length&&!("type"in a)&&!("resourceType"in a),CmsLink=mixins().extend({name:"CmsLink",props:{serverNavigation:{type:Boolean,default:!1},link:{type:Object,default:()=>({})},hideDefaultName:{type:Boolean,default:!1},dropdownLink:{type:Boolean,default:!1},to:{type:[Object,String],default:null,validator(a){if(isCmsEvent(a))return!0;if(isFromEditorList(a))return!0;if(isBundle(a))return!0;if("string"==typeof a)return!0;if(!a||"object"!=typeof a)return!1;if(isCartItem(a))return!0;if("linkV2"in a)return!0;const b="string"!=typeof a.type&&a.type?a.type.slug:a.type;if("product"===b)return!0;if("service"===b)return!0;if("post"===b)return!0;if("page"===b)return!0;// ssr or refresh page errors in console fix
if("typeSlug"in a)return!0;const c="string"!=typeof a.taxonomy&&a.taxonomy?a.taxonomy.slug:a.taxonomy;return!("category"!==c)}},query:{type:Object,default:()=>({})},target:{type:String,default:""}},computed:{toIsFromEnum(){return Object.values(this.$Page).includes(this.to)},isExternal(){const{to:a}=this;if(isFromEditorList(a)){var b;return"/"!==(null===(b=a.formattedPath)||void 0===b?void 0:b[0])}else{var c;const b="string"==typeof a&&"/"!==a[0]&&!this.toIsFromEnum;return!!b||!(!a||"string"==typeof a||isTerm(a)||isCmsEvent(a))&&!!isNavigationItem(a)&&a.isExternal&&!(null!==(c=a.externalLink)&&void 0!==c&&c.isStatic)}},validateServerLink(){const{link:a}=this;return!a.externalLink&&!a.prodcategory&&!a.link&&!a.term_id&&!a.post_id&&!a.prod_category_id||this.dropdownLink},validateNuxtLink(){return!isEmpty(this.link)&&!this.link.protocol},returnLinkRel(){let a=this.link.rel;return a||(a="dofollow"),a},returnLinkTarget(){let a=this.link.target;return a||(a="_blank"),a},returnLinkName(){return this.link?this.link.name||this.link.title||"":""},returnNuxtLink(){let a=this.link.formattedPath||this.link.linkV2||this.link.link;return a?"/"!==a.charAt(0)&&(a=`/${a}`):a="/",a},localeListAsObject(){const a={},b=this.$i18n.locales;return null===b||void 0===b?void 0:b.forEach(b=>a[b.code]=b),a},localisedTo(){const{to:a,query:b}=this;if(!a)return"/";if(isFromEditorList(a))return a.formattedPath||"/";if(isCmsEvent(a))return this.localePath({name:Page.Event,params:{id:a.id.toString()}});if(isTerm(a))return genTermPath(a,this.localePath,b);if("string"==typeof a)return this.genPathFromString(a);if(isNavigationItem(a)){const c=genNavigationItemPath(a,this.localePath);return this.isExternal?c:{path:c,query:b}}if(isCartItem(a)){const{mainProductSlug:c,slug:d,isVisibleIndividually:e}=a.productData,{slug:f,query:g=null}=c?e?{slug:d}:{slug:c,query:{...b,cartItemId:a.itemId}}:{slug:d};// If there is mainProductSlug, that means user clicked on one simple product configuration
// If user clicks on cart item which is one simple product configuration which is not visible individually, then we should take him to configurabile product single with additional query so that we can know which configuration and custom options were set
// If clicked product is just a simple product which is not some other products configuration, or is product configuration which is visible individually, dont use query
return this.localePath({path:"/product/"+f,query:g?g:b})}return isBundle(a)?this.localePath({path:"/product/"+a.slug,query:b}):"product"===a.type?this.localePath({path:"/product/"+a.slug,query:b}):"service"===a.type?this.localePath({path:"/product/"+a.slug,query:b}):"post"===a.resourceType?this.localePath({path:"/"+a.slug,query:b}):"term"===a.resourceType?this.localePath({name:this.$Page.Blog,params:{slug:a.slug},query:b}):(process.browser&&consola.error(`Cannot recognise "to" prop on CmsLink:`,a),"/")}},methods:{genPathFromString(a){const{query:b}=this;if(this.isExternal)return a;if(this.toIsFromEnum)return this.localePath({name:a,query:b});// If "to" is equal to "/en", "/hr" or something along those lines,
// it means we just need to switch the locale
const c=this.localeListAsObject[a];if(c)return this.switchLocalePath(a);const d=this.$router.resolve(a),{name:e,params:f,matched:g}=d.route;if(!g.length)return{path:a,query:b};// i18n creates multiple page components out of one page's component - one for each language.
// It names them {componentName}___{locale}, e.g. page-cart___en, page-cart___hr etc.
const h=null===e||void 0===e?void 0:e.replace(/___\w+/,"");return this.localePath({name:h,params:f,query:b})},chooseLinkType(){if(null!==this.to&&!this.isExternal){var a,b;return this.$createElement("nuxt-link",{props:{to:this.localisedTo},attrs:{// @ts-ignore
rel:this.to.rel,// @ts-ignore
target:this.to.target||this.target},on:{click:()=>this.$emit("click")}},null===(a=(b=this.$scopedSlots).default)||void 0===a?void 0:a.call(b,{}))}if(this.isExternal){var c,d;return this.$createElement("a",{attrs:{href:this.localisedTo,target:hasTarget(this.to)?this.to.target:"_blank"},on:{click:()=>this.$emit("click")}},null===(c=(d=this.$scopedSlots).default)||void 0===c?void 0:c.call(d,{}))}if(this.link&&this.serverNavigation&&this.validateServerLink){var e,f;return this.$createElement("a",{attrs:{role:"button"},class:"static",on:{click:()=>this.$emit("click")}},[this.hideDefaultName?void 0:this.returnLinkName,null===(e=(f=this.$scopedSlots).default)||void 0===e?void 0:e.call(f,{})])}if((!this.returnLinkName||""===this.returnLinkName)&&this.$isInEditMode&&!this.hideDefaultName)return this.$createElement("a","LinkPlaceholder");if(this.link&&this.link.externalLink){var g,h;return this.$createElement("a",{attrs:{href:this.link.link,rel:this.returnLinkRel,target:this.returnLinkTarget}},[this.hideDefaultName?void 0:this.returnLinkName,null===(g=(h=this.$scopedSlots).default)||void 0===g?void 0:g.call(h,{})])}if(this.link&&this.validateNuxtLink){var i,j;return this.$createElement("nuxt-link",{props:{to:this.returnNuxtLink},slot:"default"},[this.hideDefaultName?void 0:this.returnLinkName,null===(i=(j=this.$scopedSlots).default)||void 0===i?void 0:i.call(j,{})])}var k,l;return this.$createElement("a",[null===(k=(l=this.$scopedSlots).default)||void 0===k?void 0:k.call(l,{})])}},render(){return this.chooseLinkType()}});export default CmsLink;