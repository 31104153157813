import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_edda3a18 from './schemes/refresh.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":false,"logout":"/","home":false,"callback":false},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"},"name":"token"},"localStorage":false,"token":{"prefix":"token."},"refresh_token":{"prefix":"refresh_token."},"defaultStrategy":"refresh"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/user","method":"get","propertyName":"user"}},"_name":"local"}))

  // social
  $auth.registerStrategy('social', new scheme_edda3a18($auth, {"endpoints":{"login":{"url":"/api/v1/user/social/oauth","method":"post","propertyName":"data.tokens.accessToken"},"logout":false,"user":{"url":"/api/v1/user/web/profile","method":"get","propertyName":"data"},"refresh":{"url":"/api/v1/refresh","method":"get","propertyName":"data"}},"_name":"social"}))

  // refresh
  $auth.registerStrategy('refresh', new scheme_edda3a18($auth, {"endpoints":{"login":{"url":"api/v1/login","method":"post","propertyName":"data.tokens.accessToken"},"logout":false,"user":{"url":"api/v1/user/web/profile","method":"get","propertyName":"data"},"refresh":{"url":"api/v1/refresh","method":"get","propertyName":"data"}},"_name":"refresh"}))

  // admin
  $auth.registerStrategy('admin', new scheme_edda3a18($auth, {"endpoints":{"login":{"url":"api/v1/login","method":"post","propertyName":"data.tokens.accessToken"},"logout":false,"user":{"url":"api/v1/user/profile","method":"get","propertyName":"data"},"refresh":{"url":"api/v1/refresh","method":"get","propertyName":"data"}},"_name":"admin"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
