import{hasKey,mapEachKey}from"./utils/hasKey";import{formatError}from"./utils/error";import consola from"consola";// Services
import Components from"./services/Components";import Application from"./services/Application";import CartService from"./services/Cart";import ApiService from"./services/ApiService";import Display from"./services/cmsDisplay";import Wishlist from"./services/Wishlist";// Types
const CorePlugin=async(a,b)=>{// if (ctx.route.name === 'admin-login') {
//   return
// }
if(!a.$config.forceDomain&&a.isDev)return b("api",new ApiService(a)),void a.redirect("/adminLogin");b("isDevMode",a.isDev);const{gitlabBranch:c}=a.$config;b("isProduction",!!c&&!["debug","test"].includes(c));injectServices({api:ApiService,// Has to be first, other services use it
display:Display,application:Application,components:Components,cart:CartService,wishlist:Wishlist},b,a),setAxiosInterceptor(a),await fetchAllOptions(a),a.$auth.loggedIn&&a.$auth.user.isBackofficeUser&&(await a.$application.loadAdminLocale()),a.app.i18n.onLanguageSwitched=()=>{a.$components.fetchElementsConfig(),a.$application.fetchCurrencyConfig(),a.$application.fetchCountries(),a.$cart.clearSession()};const d=a.$application.findCmsOption("GoogleTagManagerIdentifier");// @ts-expect-error $gtm nije definiran
(null===d||void 0===d?void 0:d.value)&&a.$gtm.init(d.value)};export default CorePlugin;function injectServices(a,b,c){Object.keys(a).forEach(d=>{hasKey(a,d)&&b(d,new a[d](c))})}function setAxiosInterceptor(a){a.$axios.onRequest(b=>{var c,d;const{domain:e}=a.$application;if(!e)return;const{store:f}=a.$application;b.headers.common||(b.headers.common={});const{languages:g}=a.$application,{locale:h}=a.app.i18n,i=null===g||void 0===g?void 0:g.find(a=>a.code2===h),{common:j}=b.headers;i&&(j["x-gaussbox-language"]=null===i||void 0===i?void 0:i.code),null!==f&&void 0!==f&&f.storeID&&(j.store=f.storeID),a.$cookies.get("skipApiKey")||(j.apiKey=e.apiKeyToken),j.site=e.siteID,null!==(c=a.store.state)&&void 0!==c&&null!==(d=c.auth)&&void 0!==d&&d["token.refresh"]&&(j.accessToken=a.store.state.auth["token.refresh"])});let b=!1;a.$axios.onError(async c=>{var d;const f=formatError(c),e=a.app.i18n.t(f.message),g="string"==typeof e?e:f.message,h=50*g.length;if(null===(d=a.$toast)||void 0===d?void 0:d.error(g.replace("`validateLogin` failed (\"timeouts\").  Login froze\nAdditional data:\n\n",""),{duration:h}),403!==f.statusCode||b)throw c;consola.error("Wrong admin accessKey (probably changed domain while logged in as admin on old domain), logging you out."),await a.$auth.logout();// @ts-ignore
const i=a.$auth.strategies.admin.options.endpoints,j=mapEachKey(i,a=>{var b;return null===(b=i[a])||void 0===b?void 0:b.url}),k=j.filter(Boolean).includes(c.config.url);// If auth route request failed, we don't care because we logged out anyway
k||(b=!0,delete c.config.headers.accessToken,await a.$axios.request(c.config))})}async function fetchAllOptions(a){if(!process.server)return;const{$application:b,$components:c,$cart:d,$wishlist:e,$auth:f}=a,g=[b.fetchCmsOptions(),// $application.fetchSiteReviewOptions(),
c.fetchElementsConfig()];b.isEcommerce&&g.push(...[b.fetchCurrencyConfig(),d.checkSessionOnInit()]),f.loggedIn&&!f.user.isBackofficeUser&&g.push(e.fetchWishlist()),await Promise.all(g)}