var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-hidden py-8 min-h-100vh-mobile-menu md:min-h-100vh-menu md:pt-40 md:pb-48"},[_c('div',{staticClass:"content-container"},[_c('h1',{staticClass:"main-title uppercase font-extrabold overflow-hidden font-pragmatica-extended leading-none"},[_vm._v("\n      "+_vm._s(_vm.$t('hsc.post.blog'))+"\n    ")]),_vm._v(" "),_c('controller-post-category-list',{staticClass:"mt-4 mb-20 flex justify-end items-center text-1.4rem sm:mb-32 lg:mb-40",scopedSlots:_vm._u([{key:"list",fn:function({ items }){return [_c('select',{staticClass:"font-pragmatica-extended bg-white focus:outline-none",on:{"change":function($event){$event.target.value === 'blog'
              ? _vm.$router.push('/blog')
              : _vm.$router.push(
                  `${_vm.localePath(_vm.$Page.Blog)}/${$event.target.value}`
                )}}},[_c('option',{attrs:{"selected":"","value":"blog"}},[_vm._v(_vm._s(_vm.$t('hsc.post.filter')))]),_vm._v(" "),_vm._l((items.filter((el) => el.item.slug !== 'draft')),function(item){return _c('option',{key:item.item.id,domProps:{"value":item.item.slug}},[_vm._v("\n            "+_vm._s(item.item.slug === 'featured'
                ? _vm.$t('hsc.post.featured')
                : item.item.title)+"\n          ")])})],2)]}}])}),_vm._v(" "),_c('controller-post-list',{attrs:{"limit":"9"},scopedSlots:_vm._u([{key:"default",fn:function({
        items,
        options,
        paginationState,
        previousPage,
        nextPage,
        firstPage,
        lastPage,
        status,
      }){return [_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 justify-items-stretch items-stretch gap-x-6 gap-y-24"},_vm._l((items.filter(
            (el) =>
              el?.terms?.categories &&
              el?.terms?.categories.length &&
              el?.terms?.categories[0]?.slug !== 'draft'
          )),function(item){return _c('div',{key:item.id,staticClass:"flex flex-col justify-between"},[_c('div',{staticClass:"flex-shrink-0 mb-6 md:mb-10"},[_c('cms-link',{attrs:{"to":item}},[_c('cms-image',{staticClass:"w-full h-64 mb-6 object-cover object-center md:mb-10",attrs:{"media":item.media}})],1),_vm._v(" "),_c('h3',{staticClass:"two-line-truncate responsive-2-line-heading font-pragmatica-extended font-bold"},[_c('cms-link',{staticClass:"hover:underline",attrs:{"to":item}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-grow justify-between text-xl md:text-1.4rem"},[_c('p',{staticClass:"four-line-truncate leading-relaxed mb-6 text-xl md:text-1.4rem md:mb-3.5rem"},[_vm._v("\n              "+_vm._s(item.excerpt ? item.excerpt : '')+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-between"},[_c('cms-link',{staticClass:"font-bold hover:underline",attrs:{"to":item}},[_vm._v(_vm._s(_vm.$t('hsc.post.readMore'))+"\n              ")]),_vm._v(" "),_c('button',{staticClass:"inline-flex items-center leading-none focus:outline-none",on:{"click":function($event){return _vm.toggleSocialShare(item)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('hsc.post.share')))]),_vm._v(" "),_c('fa',{staticClass:"ml-3 text-2xl transitioned",attrs:{"icon":_vm.icons.basic.share}})],1)],1)])])}),0),_vm._v(" "),(paginationState.totalPages > 1)?_c('pagination',{staticClass:"mt-16 md:mt-24",attrs:{"options":options,"pagination-state":paginationState,"previous-page":previousPage,"next-page":nextPage,"first-page":firstPage,"last-page":lastPage,"disabled":status.pending}}):_vm._e()]}}])}),_vm._v(" "),_c('transition',{attrs:{"name":"fade-200ms"}},[_c('client-only',[(_vm.socialShareModalActive)?_c('social-share-modal',{attrs:{"endpoint":_vm.postToShare ? _vm.postToShare.slug : ''},on:{"closeSocialShare":_vm.closeSocialShare}}):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }