// Types
import mixins from"vue-typed-mixins";// Utils
import startCase from"lodash/startCase";import kebabCase from"lodash/kebabCase";// Mixins
import StatusUpdateable from"./StatusUpdateable";const ControllerBase=mixins(StatusUpdateable).extend({props:{clientFetch:{type:Boolean,default:!1},tag:{type:String,default:"div"}},fetchOnServer:function(){var a,b;return!this.$config.globalControllerFetchOnClient&&!(null!==(a=this.$options)&&void 0!==a&&null!==(b=a.propsData)&&void 0!==b&&b.clientFetch);// @ts-ignore
},methods:{createModularContainer(a,b={domProps:{}},c=""){// Semantics
const{name:d}=this.$options,e=this.$config.isProduction?void 0:d;b.domProps=b.domProps||{},b.class=Array.isArray(b.class)?b.class:[b.class];const f=c&&this.$isInEditMode;b.class.push(f?"relative bg-blue-200 border border-dashed border-blue-600":"");const g=f?this.$createElement("div",{class:"absolute top-0 left-0 bg-blue-600 text-sm text-white px-1"},c):void 0;return a=a?[...a,g]:[g],Object.assign(b.domProps,{"data-cms":e}),this.$createElement(this.tag,b,a)},// All properties in slot props have to be required to avoid having null
// instead of object and causing errors and crashes on package user's side
/**
     * Returns universal container for controller; If array is passed,
     * it will automatically v-for each item in array and provide slot for it
     * @param slotProps object or array that will be provided in slot
     * @param requiredProps names of props whose values not be empty, if
     * any of them are empty container will show error instead of content
     * @param labelText text to be shown as label on controller's container
     */renderContainer(a,b=[],c=""){var d,e,f,g;const i=this.$createElement,{name:h}=this.$options||{},j=Array.isArray(a)?{...Object(a[0]),index:0}:a;for(const d of b){var k;const a=null!==(null!==(k=j[d])&&void 0!==k?k:null);if(!a)return this.$isInEditMode?this.createModularContainer([i("div",{class:"bg-red-600"},["Missing required data (\"",d,"\"). Check if section is placed on proper page."])]):this.createModularContainer()}const l=j?Object.keys(j):[],m=i("div",{class:"bg-gray-200 text-center border border-gray-400 px-4"},[i("b",[startCase(h)]),i("pre",[l.length?`v-slot="{ ${l.join(", ")} }"`:"Nothing loaded..."])]),n=this._uid,o=Array.isArray(a)?(null===(d=(e=this.$scopedSlots).list)||void 0===d?void 0:d.call(e,{items:a}))||a.map((a,b)=>{var c,d,e;return null===(c=(d=this.$scopedSlots).default)||void 0===c||null===(e=c.call(d,{...a,index:b}))||void 0===e?void 0:e.map(a=>({...a,key:`${kebabCase(h)}-${b}-${n}`}))}):null===(f=(g=this.$scopedSlots).default)||void 0===f?void 0:f.call(g,a),p=!this.$scopedSlots.list&&!this.$scopedSlots.default;return this.createModularContainer(p?[m]:o,void 0,c)}}});export default ControllerBase;