<template>
  <div
    class="overflow-hidden py-8 min-h-100vh-mobile-menu md:min-h-100vh-menu md:pt-40 md:pb-48"
  >
    <div class="content-container">
      <h1
        class="main-title mb-8 overflow-hidden uppercase font-extrabold leading-tight font-pragmatica-extended md:mb-12"
      >
        {{ $t('hsc.checkout.title') }}
      </h1>
      <div>
        <div>
          <controller-address
            ref="controllerAddress"
            v-slot="{
              form,
              options,
              allowedCountries,
              submit,
              isStepActive,
              generateErrorMessage,
            }"
          >
            <div id="BillingAddress" class="dropdown-parent dropdown-label">
              <span v-if="isStepActive" class="hidden">
                {{ goToPart('BillingAddress') }}
              </span>
              <div
                class="dropdown-icon transitioned"
                :class="
                  isStepActive
                    ? 'bg-pureYellow text-white'
                    : 'bg-darkGrayishViolet text-white'
                "
              >
                1
              </div>
              <p>
                {{
                  isStepActive
                    ? $t('hsc.checkout.addresses')
                    : options.useDifferentBillingAddress
                    ? $t('hsc.checkout.differentAddresses')
                    : $t('hsc.checkout.sameAddresses')
                }}
              </p>
            </div>

            <hr />
            <div v-show="isStepActive">
              <div
                class="my-10 md:my-12 xl:grid xl:grid-cols-12 xl:items-start xl:gap-5"
              >
                <form
                  class="xl:col-start-1 xl:col-end-9"
                  @submit.prevent="submit"
                >
                  <fieldset
                    class="form-box"
                    :class="{ 'mb-10': options.useDifferentBillingAddress }"
                  >
                    <p
                      class="form-label mb-1.8rem uppercase font-pragmatica-extended font-extrabold"
                    >
                      {{ $t('hsc.checkout.shippingAddress') }}
                    </p>
                    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6">
                      <section class="col-span-2">
                        <div class="flex items-center mb-5 gap-4">
                          <input id="r1" v-model="r1" type="checkbox" />
                          <label
                            for="r1"
                            class="form-label cursor-pointer hover:underline"
                          >
                            {{ $t('hsc.placeholders.r1') }}
                          </label>
                        </div>
                      </section>
                      <section v-if="r1">
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.companyName') }}*
                          <input
                            v-model.trim="form.deliveryForm.companyName"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.companyName')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage(
                              'form.deliveryForm.companyName'
                            )
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage(
                              'form.deliveryForm.companyName'
                            )
                          }}
                        </p>
                      </section>
                      <section v-if="r1">
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.companyOib') }}*
                          <input
                            v-model.trim="form.deliveryForm.companyOIB"
                            type="number"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.companyOib')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.deliveryForm.companyOib')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.deliveryForm.companyOib')
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.firstName') }}*
                          <input
                            v-model.trim="form.deliveryForm.firstName"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.firstName')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.deliveryForm.firstName')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.deliveryForm.firstName')
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.lastName') }}*
                          <input
                            v-model.trim="form.deliveryForm.lastName"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.lastName')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.deliveryForm.lastName')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.deliveryForm.lastName')
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.email') }}*
                          <input
                            v-model.trim="form.deliveryForm.contactEmail"
                            type="email"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.email')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage(
                              'form.deliveryForm.contactEmail'
                            )
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage(
                              'form.deliveryForm.contactEmail'
                            )
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.phone') }}*
                          <input
                            v-model.trim="form.deliveryForm.contactPhone"
                            type="tel"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.phone')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage(
                              'form.deliveryForm.contactPhone'
                            )
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage(
                              'form.deliveryForm.contactPhone'
                            )
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.street') }}*
                          <input
                            v-model.trim="form.deliveryForm.street"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.street')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.deliveryForm.street')
                          "
                          class="error-msg"
                        >
                          {{ generateErrorMessage('form.deliveryForm.street') }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.houseNumber') }}*
                          <input
                            v-model.trim="form.deliveryForm.houseNumber"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.houseNumber')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage(
                              'form.deliveryForm.houseNumber'
                            )
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage(
                              'form.deliveryForm.houseNumber'
                            )
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.country') }}*
                          <select
                            v-model="form.deliveryForm.country"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.country')"
                          >
                            <option value="">
                              {{ $t('hsc.checkout.pickACountry') }}
                            </option>
                            <template
                              v-for="(country, index) in allowedCountries"
                            >
                              <option
                                :key="country.iso"
                                :value="country.iso"
                                :selected="index === 0"
                              >
                                {{ country.name }}
                              </option>
                            </template>
                          </select>
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.deliveryForm.country')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.deliveryForm.country')
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.city') }}*
                          <input
                            v-model.trim="form.deliveryForm.city"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.city')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.deliveryForm.city')
                          "
                          class="error-msg"
                        >
                          {{ generateErrorMessage('form.deliveryForm.city') }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.postalCode') }}*
                          <input
                            v-model.trim="form.deliveryForm.zipCode"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.postalCode')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.deliveryForm.zipCode')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.deliveryForm.zipCode')
                          }}
                        </p>
                      </section>
                      <section
                        class="flex items-end leading-none text-xl md:text-1.6rem"
                      >
                        <div class="mt-5">
                          <label class="flex items-center mb-5">
                            <input
                              v-model="options.useDifferentBillingAddress"
                              type="radio"
                              :value="false"
                            />
                            <span class="ml-2">{{
                              $t('hsc.checkout.useSameBillingAddress')
                            }}</span>
                          </label>
                          <label class="flex items-center">
                            <input
                              v-model="options.useDifferentBillingAddress"
                              type="radio"
                              :value="true"
                            />
                            <span class="ml-2">{{
                              $t('hsc.checkout.useDifferentBillingAddress')
                            }}</span>
                          </label>
                        </div>
                      </section>
                    </div>
                    <multiple-bordered-button
                      v-show="!options.useDifferentBillingAddress"
                      primary-color-name="darkGrayishViolet"
                      text-color-name="white"
                      is-submit
                      class="mt-8 md:mt-3.5rem"
                    >
                      <span class="btn-text">{{
                        $t('hsc.form.actions.continue')
                      }}</span>
                    </multiple-bordered-button>
                  </fieldset>

                  <fieldset
                    v-show="options.useDifferentBillingAddress"
                    class="form-box"
                  >
                    <p
                      class="form-label mb-1.8rem uppercase font-pragmatica-extended font-extrabold"
                    >
                      {{ $t('hsc.checkout.billingAddress') }}
                    </p>
                    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6">
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.firstName') }}*
                          <input
                            v-model.trim="form.billingForm.firstName"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.firstName')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.billingForm.firstName')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.billingForm.firstName')
                          }}*
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.lastName') }}*
                          <input
                            v-model.trim="form.billingForm.lastName"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.lastName')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.billingForm.lastName')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.billingForm.lastName')
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.email') }}*
                          <input
                            v-model.trim="form.billingForm.contactEmail"
                            type="email"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.email')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage(
                              'form.billingForm.contactEmail'
                            )
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage(
                              'form.billingForm.contactEmail'
                            )
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.phone') }}*
                          <input
                            v-model.trim="form.billingForm.contactPhone"
                            type="tel"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.phone')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage(
                              'form.billingForm.contactPhone'
                            )
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage(
                              'form.billingForm.contactPhone'
                            )
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.street') }}*
                          <input
                            v-model.trim="form.billingForm.street"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.street')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.billingForm.street')
                          "
                          class="error-msg"
                        >
                          {{ generateErrorMessage('form.billingForm.street') }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.houseNumber') }}*
                          <input
                            v-model.trim="form.billingForm.houseNumber"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.houseNumber')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.billingForm.houseNumber')
                          "
                          class="error-msg"
                        >
                          {{
                            generateErrorMessage('form.billingForm.houseNumber')
                          }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.country') }}*
                          <select
                            v-model="form.billingForm.country"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.country')"
                          >
                            <option value="">Please pick a country</option>
                            <template v-for="country in allowedCountries">
                              <option
                                :key="country.iso"
                                selected
                                :value="country.iso"
                              >
                                {{ country.name }}
                              </option>
                            </template>
                          </select>
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.billingForm.country')
                          "
                          class="error-msg"
                        >
                          {{ generateErrorMessage('form.billingForm.country') }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.city') }}*
                          <input
                            v-model.trim="form.billingForm.city"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.city')"
                          />
                        </label>
                        <p
                          v-show="generateErrorMessage('form.billingForm.city')"
                          class="error-msg"
                        >
                          {{ generateErrorMessage('form.billingForm.city') }}
                        </p>
                      </section>
                      <section>
                        <label class="form-label">
                          {{ $t('hsc.form.inputs.postalCode') }}*
                          <input
                            v-model.trim="form.billingForm.zipCode"
                            type="text"
                            class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                            :placeholder="$t('hsc.form.inputs.postalCode')"
                          />
                        </label>
                        <p
                          v-show="
                            generateErrorMessage('form.billingForm.zipCode')
                          "
                          class="error-msg"
                        >
                          {{ generateErrorMessage('form.billingForm.zipCode') }}
                        </p>
                      </section>
                    </div>

                    <multiple-bordered-button
                      v-show="options.useDifferentBillingAddress"
                      primary-color-name="darkGrayishViolet"
                      text-color-name="white"
                      is-submit
                      class="mt-8 md:mt-3.5rem"
                    >
                      <span class="btn-text">{{
                        $t('hsc.form.actions.continue')
                      }}</span>
                    </multiple-bordered-button>
                  </fieldset>
                </form>
                <order-review
                  :hide-code="hideCode"
                  class="hidden xl:block xl:col-start-9 xl:col-end-13"
                />
              </div>
              <hr />
            </div>
          </controller-address>
          <controller-shipping
            v-slot="{
              submit,
              isStepActive,
              form,
              shippingMethods,
              goToPreviousStep,
            }"
          >
            <div id="ShippingPart" class="dropdown-parent dropdown-label">
              <span v-if="isStepActive" class="hidden">
                {{ goToPart('ShippingPart') }}
              </span>
              <div
                class="dropdown-icon transitioned"
                :class="
                  isStepActive
                    ? 'bg-pureYellow text-white'
                    : 'bg-darkGrayishViolet text-white'
                "
              >
                2
              </div>
              <p>
                {{ $t('hsc.checkout.deliveryMethod') }}
              </p>
            </div>

            <hr />

            <div v-show="isStepActive">
              <div
                class="my-10 md:my-12 xl:grid xl:grid-cols-12 xl:items-start xl:gap-5"
              >
                <form
                  class="form-box sm:inline-block xl:col-start-1 xl:col-end-9"
                  @submit.prevent="submit"
                >
                  <p
                    class="form-label mb-1.8rem uppercase font-pragmatica-extended font-extrabold"
                  >
                    {{ $t('hsc.checkout.deliveryMethods') }}
                  </p>
                  <section
                    v-for="shippingMethod in shippingMethods"
                    :key="shippingMethod.id"
                    class="mt-4 grid grid-cols-2 form-label"
                  >
                    <label class="flex items-center justify-start space-x-3">
                      <input
                        v-model="form.methodId"
                        type="radio"
                        name="shippingMethod"
                        :value="shippingMethod.id"
                        :checked="form.methodId === shippingMethod.id"
                      />
                      <span v-if="shippingMethod.price" class="leading-none"
                        >{{ $currency(shippingMethod.price) }}
                        <span
                          v-if="
                            shippingMethod.price &&
                            returnEuroPrice(shippingMethod.price)
                          "
                          class="text-ps font-bold"
                        >
                          ({{ returnEuroPrice(shippingMethod.price) }})
                        </span>
                      </span>
                      <span v-else class="leading-none">{{
                        $t('hsc.cart.free')
                      }}</span>
                    </label>
                    <span
                      class="font-bold leading-normal text-right sm:text-left"
                      >{{ shippingMethod.name }}</span
                    >
                    <div
                      v-if="
                        form.methodId === shippingMethod.id &&
                        shippingMethod.options &&
                        shippingMethod.options.length
                      "
                      class="col-span-full"
                    >
                      <p
                        class="form-label pt-6 pb-4 uppercase font-pragmatica-extended font-extrabold"
                      >
                        {{ $t('hsc.checkout.selectBranch') }}:
                      </p>
                      <div class="flex flex-col pl-5 space-y-5">
                        <label
                          v-for="option in shippingMethod.options"
                          :key="option.id"
                          class="w-full flex items-center justify-start space-x-3"
                        >
                          <input
                            v-model="form.branchId"
                            type="radio"
                            name="deliveryStore"
                            :value="option.id"
                            :checked="form.branchId === option.id"
                          />
                          <span class="leading-none">{{
                            option.name
                              ? option.name
                              : $t('hsc.checkout.unknownBranchName')
                          }}</span>
                        </label>
                      </div>
                    </div>
                  </section>
                  <section
                    class="mt-8 flex flex-col space-y-4 md:mt-3.5rem md:flex-row md:space-y-0 md:space-x-4.5rem"
                  >
                    <multiple-bordered-button
                      primary-color-name="darkGrayishViolet"
                      text-color-name="darkGrayishViolet"
                      is-transparent
                      @clicked="goToPreviousStep"
                    >
                      <span class="btn-text">{{
                        $t('hsc.form.actions.previousStep')
                      }}</span>
                    </multiple-bordered-button>

                    <multiple-bordered-button
                      :disabled="
                        !selectedMethodValid(
                          shippingMethods,
                          form.methodId,
                          form.branchId
                        )
                      "
                      primary-color-name="darkGrayishViolet"
                      text-color-name="white"
                      is-submit
                    >
                      <span class="btn-text">{{
                        $t('hsc.form.actions.continue')
                      }}</span>
                    </multiple-bordered-button>
                  </section>
                </form>
                <order-review
                  :hide-code="hideCode"
                  class="hidden xl:block xl:col-start-9 xl:col-end-13"
                />
              </div>

              <hr />
            </div>
          </controller-shipping>
          <controller-payment
            v-slot="{
              submit,
              isStepActive,
              form,
              paymentMethods,
              goToPreviousStep,
              consents,
              status,
            }"
            @submitted="setPurchasedItems"
          >
            <div id="PaymentMethod" class="dropdown-parent dropdown-label">
              <span v-if="isStepActive" class="hidden">
                {{ goToPart('PaymentMethod') }}
              </span>
              <div
                class="dropdown-icon transitioned"
                :class="
                  isStepActive
                    ? 'bg-pureYellow text-darkGrayishViolet'
                    : 'bg-darkGrayishViolet text-white'
                "
              >
                3
              </div>
              <p>
                {{ $t('hsc.checkout.paymentMethod') }}
              </p>
            </div>

            <hr />

            <div v-show="isStepActive">
              <div
                class="my-10 md:my-12 xl:grid xl:grid-cols-12 xl:items-start xl:gap-5"
              >
                <form
                  class="form-box sm:inline-block xl:col-start-1 xl:col-end-9"
                  @submit.prevent="submit"
                >
                  <p
                    class="form-label mb-1.8rem uppercase font-pragmatica-extended font-extrabold"
                  >
                    {{ $t('hsc.checkout.paymentMethods') }}
                  </p>
                  <section
                    v-for="paymentMethod in paymentMethods"
                    :key="paymentMethod.id"
                    class="mt-6 form-label"
                  >
                    <label class="flex items-center justify-start space-x-3">
                      <input
                        v-model="form.methodId"
                        type="radio"
                        name="paymentMethod"
                        :checked="paymentMethod.id === form.methodId"
                        :value="paymentMethod.id"
                      />
                      <span class="font-bold leading-none">{{
                        paymentMethod.name
                      }}</span>
                    </label>
                    <p class="mt-2 ml-8 text-lg md:text-1.4rem">
                      {{ paymentMethod.description }}
                    </p>
                  </section>

                  <section class="mt-10 text-lg md:text-1.4rem">
                    <div
                      v-for="consent in consents"
                      :key="consent.uniqueNumber"
                      class="mt-6 flex items-center"
                    >
                      <input
                        v-model="form.acceptedConsents"
                        type="checkbox"
                        :value="consent.uniqueNumber"
                        class="mr-2 md:mr-4 md:w-8 md:h-8"
                      />
                      <div
                        class="leading-tight inline"
                        v-html="consent.displayName"
                      />
                    </div>
                  </section>

                  <section
                    class="mt-8 flex flex-col space-y-4 md:mt-3.5rem md:flex-row md:space-y-0 md:space-x-4.5rem"
                  >
                    <multiple-bordered-button
                      primary-color-name="darkGrayishViolet"
                      text-color-name="darkGrayishViolet"
                      is-transparent
                      :disabled="status.pending"
                      @clicked="goToPreviousStep"
                    >
                      <span class="btn-text">{{
                        $t('hsc.form.actions.previousStep')
                      }}</span>
                    </multiple-bordered-button>

                    <multiple-bordered-button
                      :disabled="
                        !form.methodId ||
                        form.acceptedConsents.length !== consents.length ||
                        status.pending
                      "
                      primary-color-name="pureYellow"
                      text-color-name="darkGrayishViolet"
                      is-submit
                    >
                      <span class="btn-text">{{
                        $t('hsc.form.actions.placeOrder')
                      }}</span>
                    </multiple-bordered-button>
                  </section>
                </form>
                <order-review
                  :hide-code="hideCode"
                  class="hidden xl:block xl:col-start-9 xl:col-end-13"
                />
              </div>
              <hr />
            </div>
          </controller-payment>
        </div>
        <div class="mt-10 sm:flex items-center justify-center xl:hidden">
          <order-review :hide-code="hideCode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControllerAddress from 'Controllers/checkout/ControllerAddress'
import ControllerShipping from 'Controllers/checkout/ControllerShipping'
import ControllerPayment from 'Controllers/checkout/ControllerPayment'
import OrderReview from '@/components/ui/forms/OrderReview'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import { mapActions } from 'vuex'

export default {
  components: {
    ControllerAddress,
    ControllerShipping,
    ControllerPayment,
    OrderReview,
    MultipleBorderedButton,
  },
  data() {
    return {
      hideCode: false,
      r1: false,
    }
  },
  computed: {
    currentLanguage() {
      return this.$i18n.localeProperties.iso
    },
  },

  async mounted() {
    await this.$refs.controllerAddress.fetchUserData()
    await this.$nextTick()
    // if (this.currentLanguage === 'en') {
    //   this.$set(this.$refs.controllerAddress.form.deliveryForm, 'country', 'US')
    //   this.$set(this.$refs.controllerAddress.form.billingForm, 'country', 'US')
    // } else {
    //   this.$set(this.$refs.controllerAddress.form.deliveryForm, 'country', 'HR')
    //   this.$set(this.$refs.controllerAddress.form.billingForm, 'country', 'HR')
    // }

    this.$watch(
      () => {
        return this.$parent.stepState.activeStep
      },
      (v) => {
        this.hideCode = v !== 'address'
      }
    )
  },
  // test
  methods: {
    ...mapActions(['addCartItems']),
    goToPart(part) {
      setTimeout(() => {
        if (!part || !part.length) return
        if (document) {
          const el = document.getElementById(`${part}`)
          if (!el) return
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        }
      }, 1000)
    },
    setPurchasedItems() {
      if (this.$cart && this.$cart.storage && this.$cart.storage.cart) {
        this.addCartItems(this.$cart.storage.cart)
      }
    },
    selectedMethodValid(shippingMethods, methodId, branchId) {
      if (!methodId) return false
      const selectedMethod = shippingMethods.find(
        (method) => method.id === methodId
      )
      // Somehow method was not found
      if (!selectedMethod) return false
      // Selected method does not include branches
      else if (selectedMethod && !selectedMethod.options) return true
      // Selected method includes branches, but no branch is selected
      else if (!branchId) return false
      // Selected method includes branches and branch is selected
      return true
    },
  },
}
</script>

<style scoped>
.dropdown-parent {
  @apply flex items-center py-6;
}

.dropdown-icon {
  @apply h-10 w-10 rounded-full flex items-center justify-center flex-shrink-0 mr-5;
}

.dropdown-label {
  @apply text-xl leading-tight font-pragmatica-extended;
}

@media only screen and (min-width: 768px) {
  .dropdown-icon {
    @apply h-4.5rem w-4.5rem mr-8;
  }

  .dropdown-label {
    @apply text-2.4rem;
  }
}
</style>
