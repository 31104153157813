<template>
  <controller-cookie v-slot="{ isShown, acceptCookies }" @click.stop>
    <div
      v-if="isShown"
      class="p-3 fixed flex flex-col justify-between left-50% transform -translate-x-1/2 bg-darkGrayishViolet-85 w-11/12 rounded-lg text-sm shadow-lg md:w-auto md:text-xl"
    >
      <p class="mb-8 text-white">
        {{ $t('hsc.cookies.description') }}
      </p>
      <button
        class="px-4 py-2 uppercase bg-pureYellow font-semibold self-center rounded-lg transitioned hover:opacity-75 focus:outline-none"
        @click.stop="acceptCookies"
      >
        {{ $t('hsc.form.actions.agree') }}
      </button>
    </div>
  </controller-cookie>
</template>

<script>
import ControllerCookie from 'Controllers/ControllerCookie'
export default {
  components: {
    ControllerCookie,
  },
}
</script>
