import Vue from 'vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faUserSecret,
  faShoppingCart,
  faChevronLeft,
  faChevronDown,
  faShareAlt,
  faAngleLeft,
  faAngleRight,
  faChevronRight,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faShareAlt,
  faUserSecret,
  faShoppingCart,
  faUser,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faAngleLeft,
  faAngleRight
)

/* add font awesome icon component */
Vue.component('fa', FontAwesomeIcon)
