<template>
  <div class="view-container">
    <div
      class="w-full text-center flex flex-col items-center justify-center mb-20 sm:mb-32 xl:mb-0"
    >
      <div
        class="uppercase font-pragmatica-extended text-2xl font-semibold mb-4 sm:mb-6 sm:text-5xl"
      >
        {{ $t('hsc.checkout.checkoutSuccess') }}: #{{ $route.query.orderSlug }}
      </div>
      <p
        class="uppercase font-pragmatica-extended text-2xl font-semibold mb-4 sm:mb-6 sm:text-5xl"
      >
        {{ $t('hsc.checkout.success') }}.
      </p>
      <p
        class="uppercase font-pragmatica-extended text-2xl font-semibold mb-12 sm:mb-24 sm:text-5xl"
      >
        {{ $t('hsc.checkout.thanks') }}!
      </p>

      <cms-link to="/" class="mb-12 w-1/2 xl:w-1/3 md:mb-24">
        <cms-image
          id="hsc-navbar-logo"
          :link="$siteLogo"
          class="w-full h-full"
        />
      </cms-link>

      <cms-link
        to="/"
        class="text-xl text-lightGrayishBlue leading-none flex items-center justify-center transitioned hover:text-pureYellow sm:text-3xl"
      >
        <fa
          :icon="icons.basic.leftArrow"
          class="mr-3 text-pureYellow transitioned"
        />
        <span> {{ $t('hsc.cart.backToHomePage') }}</span></cms-link
      >
    </div>
  </div>
</template>
<script>
import FaIcons from '@/mixins/FaIcons'
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [FaIcons],
  computed: {
    ...mapGetters(['cartObject']),
  },
  async mounted() {
    await this.$nextTick()
    this.sendData()
    setTimeout(() => {
      this.clearCartState()
    }, 1000)
  },
  methods: {
    ...mapActions(['clearCartState']),
    sendData() {
      let items = null
      if (
        !this.cartObject ||
        !this.cartObject.items ||
        !this.cartObject.items.length
      ) {
        this.$toast.info('No items found in cart!')
        return
      }
      items = this.cartObject.items.map((el) => {
        return {
          item_name: el.productData.name,
          item_id: el.productId,
          quantity: el.qty,
          item_category: el.productData.categories[0],
          price: el.prices.base.finalPrice,
        }
      })
      if (items && items.length) {
        let obj = {}
        if (this.cartObject.couponCode) {
          // IF EVENT HAS COUPON THEN SEND OBJECT WITH COUPON PORPERTY TO GOOGLE
          obj = {
            event: 'purchase',
            ecommerce: {
              transaction_id: `${
                this.$route.query.orderSlug || this.cartObject.id
              }`,
              value: `${this.cartObject?.prices?.converted?.orderTotal}`,
              tax: `${this.cartObject?.prices?.converted?.tax?.orderTotal}`,
              currency: 'EUR',
              coupon: this.cartObject.couponCode,
              items,
            },
          }
        } else {
          // IF EVENT HAS NO COUPON THEN SEND OBJECT WITH COUPON PORPERTY TO GOOGLE
          obj = {
            event: 'purchase',
            ecommerce: {
              transaction_id: `${
                this.$route.query.orderSlug || this.cartObject.id
              }`,
              value: `${this.cartObject?.prices?.converted?.orderTotal}`,
              tax: `${this.cartObject?.prices?.converted?.tax?.orderTotal}`,
              currency: 'EUR',
              items,
            },
          }
        }

        window.dataLayer?.push(obj)
      }
    },
  },
  head() {
    return {
      script: [
        {
          mvid: 'extscript',
          innerHTML: `<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PXKHNS6J');
<!-- End Google Tag Manager -->`,
        },
        {
          hid: 'gtm',
          innerHTML: `window.dataLayer = window.dataLayer || [];`,
          type: 'text/javascript',
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }
  },
}
</script>
<style scoped>
a:hover svg {
  @apply text-pureYellow transform scale-110;
}
</style>
