var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"flex flex-col items-center justify-center text-lg sm:flex-row md:text-1.4rem",class:_vm.growAfterSmallScreen ? 'sm:flex' : 'sm:inline-flex',on:{"submit":function($event){$event.preventDefault();return _vm.submitMethod.apply(null, arguments)}}},[_c('input',{staticClass:"h-12 w-full flex-shrink text-center tracking-0.14rem placeholder-lightGrayishBlue sm:h-16 sm:px-3 sm:w-auto sm:text-left focus:outline-none",class:[
      `bg-${_vm.inputBgColor}`,
      _vm.maxInputWidthClass ? `sm:${_vm.maxInputWidthClass}` : '',
      {
        'border-t border-l border-r sm:border-b sm:border-r-0 border-lightGrayishBlue':
          _vm.applyBorders,
      },
    ],attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"h-12 w-full sm:w-auto px-4 flex-grow flex-shrink-0 bg-darkGrayishViolet tracking-0.14rem uppercase text-white font-pragmatica-extended font-extrabold transition-opacity duration-500 ease-in-out sm:h-16 sm:px-8 focus:outline-none",class:[
      { 'opacity-80 cursor-not-allowed': !_vm.value },
      {
        'border-b border-l border-r sm:border-b sm:border-l-0 border-darkGrayishViolet':
          _vm.applyBorders,
      },
    ],attrs:{"type":"submit","disabled":!_vm.value}},[_vm._v("\n    "+_vm._s(_vm.btnText)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }