// Types
import mixins from"vue-typed-mixins";// Mixins
import ControllerBase from"../../../mixins/ControllerBase";import{WithPartialInject}from"../../../utils/withInject";// Utils
import consola from"consola";// Enums
import{AddressType,CheckoutSteps}from"../../../enums/Checkout";const ControllerShipping=mixins(ControllerBase,WithPartialInject("delivery","stepState","goToNextStep","goToPreviousStep")).extend({name:"ControllerShipping",props:{skipShippingStep:{type:Boolean,default:!1}},data(){var a;return{orderStatus:!0,shippingMethods:[],form:{methodId:(null===(a=this.$cart.storage.cart)||void 0===a?void 0:a.deliveryMethodId)||0,branchId:null},lastSavedMethod:0,lastSavedBranch:null,debounce:null}},computed:{isStepActive(){return this.stepState.activeStep===CheckoutSteps.Shipping},user(){return this.$auth.user||this.$cart.guestUser},branchId(){const{options:a}=this.$cart.storage.cart||{};return a?JSON.parse(a).deliveryBranchId||null:null}},watch:{"delivery.country":"getApplicableDeliveryMethods",// 'form.methodId': 'saveDeliveryMethod',
"user.id":"getApplicableDeliveryMethods","user.addresses":"getApplicableDeliveryMethods"// TODO: debounce or smth
},created(){this.getApplicableDeliveryMethods(),this.form.branchId=this.branchId},methods:{async getApplicableDeliveryMethods(){var a,b;if(this.$cart.isEProductCart)return;if(!this.$cart.session)return void consola.error("Cart session not initialised!");const{user:c}=this;if(!c||!c.id||!c.addresses)return;this.$application.countries.length||(await this.$application.fetchCountries());const{country:d}=this.delivery,e=null===(a=this.$application.countries.getByIso(d||""))||void 0===a?void 0:a.id;if(!e)return;const f=a=>c.addresses.find(b=>b.type===a),g=f(AddressType.Main),h=f(AddressType.Shipping);if(!(null!==g&&void 0!==g&&g.id)||!(null!==h&&void 0!==h&&h.id))return;const i={userId:c.id,cartId:this.$cart.session.id,protectCode:this.$cart.session.protectCode,countryId:e,currencyId:(null===(b=this.$application.currency)||void 0===b?void 0:b.id)||0,userBillingAddressId:g.id,userShippingAddressId:h.id},{delivery:j}=this.$api.ecommerce.checkout,k=await j.getApplicableDeliveryMethods(i),l=this.shippingMethods;if(this.skipShippingStep&&"shipping"===this.stepState.activeStep&&l.length&&k&&k.data&&k.data[0])// Tsk-10360-23
//@ts-ignore
{//@ts-ignore
const{id:a}=k.data[0];a&&(this.form.methodId=a,await this.submit())}return k.errored?void l.splice(0):void l.splice(0,l.length,...k.data)},async saveDeliveryMethod(a=0,b=0,c=null,d=null){if(a!==this.lastSavedMethod||c!==this.lastSavedBranch){const e=await this.callWithStatusUpdate(this.$cart.updateDeliveryMethod(a,c));return e.errored?(this.form.methodId=b,void(this.form.branchId=d)):void(this.lastSavedMethod=a,this.lastSavedBranch=c)}},validate(){if(!this.form.methodId)return this.$toast.error(this.$t("error.shippingMethodUndefined")),!1;const a=this.shippingMethods.find(a=>a.id===this.form.methodId);// Somehow method was not found
return a?!a.options||!!this.form.branchId||(this.$toast.error(this.$t("error.shippingBranchUndefined")),!1):(this.$toast.error(this.$t("error.shippingMethodNotFound")),!1);// Selected method includes branches and branch is selected
},async submit(){if(this.orderStatus){var a;if(this.orderStatus=!1,!this.validate())return void(this.orderStatus=!0);await this.saveDeliveryMethod(this.form.methodId,this.lastSavedMethod,this.form.branchId,this.lastSavedBranch),this.orderStatus=!0,null===(a=this.goToNextStep)||void 0===a?void 0:a.call(this),this.$emit("submitted")}}},render(){const{shippingMethods:a,form:b,status:c,isStepActive:d,submit:e,goToPreviousStep:f}=this;return this.renderContainer({shippingMethods:a,form:b,status:c,isStepActive:d,submit:e,goToPreviousStep:f})}});export default ControllerShipping;