<template>
  <div class="view-container bg-login-background bg-left bg-cover">
    <div class="content-container">
      <h1
        class="main-title mb-12 uppercase overflow-hidden font-extrabold leading-tight font-pragmatica-extended xl:mb-7rem"
      >
        {{ $t('hsc.forgotPassword.title') }}
      </h1>
      <div
        class="flex flex-col items-start justify-between xl:flex-row xl:items-start"
      >
        <p
          class="mb-12 text-xl overflow-hidden leading-loose font-pragmatica-extended md:mb-24 md:text-1.6rem xl:mt-8 xl:w-5/12 xl:mb-0"
        >
          {{ $t('hsc.forgotPassword.description') }}
        </p>
        <controller-forgot-password
          v-if="!$route.query.token"
          v-slot="{ form, submit, status, generateErrorMessage }"
          class="self-center w-11/12 px-5 pt-5 pb-6 bg-whiteSmoke rounded-lg sm:w-4/6 lg:w-3/5 xl:w-1/2 md:px-8 md:pt-8 md:pb-10"
          @submitted="handleSuccess"
        >
          <form @submit.prevent="submit">
            <section class="mb-10 md:mb-4.5rem">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.email') }}*
                <input
                  v-model.trim="form.username"
                  type="email"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.email')"
              /></label>
              <p
                v-show="generateErrorMessage('form.username')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.username') }}
              </p>
            </section>
            <section>
              <multiple-bordered-button
                primary-color-name="darkGrayishViolet"
                text-color-name="white"
                is-submit
                :disabled="status.pending"
              >
                <span class="btn-text">{{ $t('hsc.form.actions.send') }}</span>
              </multiple-bordered-button>
            </section>
          </form>
        </controller-forgot-password>
        <controller-reset-password
          v-else
          v-slot="{ form, submit, status, generateErrorMessage }"
          class="self-center w-11/12 px-5 pt-5 pb-6 bg-whiteSmoke rounded-lg sm:w-4/6 lg:w-3/5 xl:w-1/2 md:px-8 md:pt-8 md:pb-10"
          @submitted="handleSuccess"
        >
          <form @submit.prevent="submit">
            <section class="mb-5">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.newPassword') }}*
                <div class="relative">
                  <input
                    v-model.trim="form.newPassword"
                    :type="
                      passwordOptions.newPasswordVisible ? 'text' : 'password'
                    "
                    class="form-text form-input password-input-padding placeholder-lightGrayishBlue focus:outline-none"
                    :placeholder="$t('hsc.form.inputs.newPassword')"
                  />
                  <fa
                    :icon="
                      passwordOptions.newPasswordVisible
                        ? `${icons.basic.eyeSlash}`
                        : `${icons.basic.eye}`
                    "
                    class="password-eye-icon transitioned"
                    @click.stop="toggleNewPasswordVisibility"
                  />
                </div>
              </label>
              <p
                v-show="generateErrorMessage('form.newPassword')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.newPassword') }}
              </p>
            </section>
            <section class="mb-10 md:mb-4.5rem">
              <label class="form-label"
                >{{ $t('hsc.form.inputs.repeatNewPassword') }}*
                <div class="relative">
                  <input
                    v-model.trim="form.newPasswordAgain"
                    :type="
                      passwordOptions.newPasswordRepeatedVisible
                        ? 'text'
                        : 'password'
                    "
                    class="form-text form-input password-input-padding placeholder-lightGrayishBlue focus:outline-none"
                    :placeholder="$t('hsc.form.inputs.repeatNewPassword')"
                  />
                  <fa
                    :icon="
                      passwordOptions.newPasswordRepeatedVisible
                        ? `${icons.basic.eyeSlash}`
                        : `${icons.basic.eye}`
                    "
                    class="password-eye-icon transitioned"
                    @click.stop="toggleNewPasswordRepeatedVisibility"
                  />
                </div>
              </label>
              <p
                v-show="generateErrorMessage('form.newPasswordAgain')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.newPasswordAgain') }}
              </p>
            </section>
            <section>
              <multiple-bordered-button
                primary-color-name="darkGrayishViolet"
                text-color-name="white"
                is-submit
                :disabled="status.pending"
              >
                <span class="btn-text">{{ $t('hsc.form.actions.send') }}</span>
              </multiple-bordered-button>
            </section>
          </form>
        </controller-reset-password>
      </div>
    </div>
  </div>
</template>

<script>
import ControllerForgotPassword from 'Controllers/user/ControllerForgotPassword'
import ControllerResetPassword from 'Controllers/user/ControllerResetPassword'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import FaIcons from '@/mixins/FaIcons'
export default {
  components: {
    ControllerForgotPassword,
    ControllerResetPassword,
    MultipleBorderedButton,
  },
  mixins: [FaIcons],
  data() {
    return {
      passwordOptions: {
        newPasswordVisible: false,
        newPasswordRepeatedVisible: false,
      },
    }
  },
  methods: {
    handleSuccess() {
      this.$router.push(this.localePath(this.$Page.Login))
    },
    toggleNewPasswordVisibility() {
      this.passwordOptions.newPasswordVisible =
        !this.passwordOptions.newPasswordVisible
    },
    toggleNewPasswordRepeatedVisibility() {
      this.passwordOptions.newPasswordRepeatedVisible =
        !this.passwordOptions.newPasswordRepeatedVisible
    },
  },
}
</script>
