<template>
  <div class="view-container relative">
    <controller-cart
      v-slot="{
        items,
        deleteCartItem,
        priceSummary,
        form,
        cartState,
        applyCoupon,
        removeCoupon,
      }"
      class="content-container"
    >
      <div
        v-if="!items.length"
        class="absolute z-10 w-full text-center flex flex-col items-center justify-center top-40% left-50% transform -translate-y-1/2 -translate-x-1/2"
      >
        <p
          class="uppercase font-pragmatica-extended text-2xl font-semibold mb-6 sm:mb-12 sm:text-5xl"
        >
          {{ $t('hsc.cart.empty') }}!
        </p>
        <cms-link
          to="/"
          class="text-xl text-lightGrayishBlue leading-none flex items-center justify-center transitioned hover:text-pureYellow sm:text-3xl"
          ><fa
            :icon="icons.basic.leftArrow"
            class="mr-3 text-pureYellow transitioned"
          /><span> {{ $t('hsc.cart.backToHomePage') }}</span></cms-link
        >
      </div>
      <div v-else>
        <h1
          class="main-title mb-3 uppercase font-extrabold leading-tight font-pragmatica-extended"
        >
          {{ $t('hsc.cart.title') }}
        </h1>
        <p
          class="uppercase text-responsive-14px leading-none font-pragmatica-extended"
        >
          {{ $t('hsc.cart.subtitle') }}
        </p>

        <div class="grid grid-cols-12 mt-10 md:mt-24">
          <div
            v-for="(item, index) in items"
            :key="item.itemId"
            class="left-grid-section"
          >
            <cms-image
              :media="item.productData.media"
              class="w-9rem h-9rem lg:w-48 lg:h-48 xl:w-64 xl:h-64 p-2 lg:p-4 xl:p-6 object-contain object-center"
              :class="`row-start-${index + 1} row-end-${index + 2}`"
            />
          </div>

          <div
            v-for="(item, index) in items"
            :key="item.productId"
            class="middle-grid-section flex flex-col py-5 bottom-horizontal-rule md:py-0 md:items-center md:justify-between md:flex-row"
            :class="`row-start-${index + 1} row-end-${index + 2}`"
          >
            <div class="max-w-110px sm:hidden mr-5">
              <figure class="mb-6">
                <cms-image :media="item.productData.media" />
              </figure>
            </div>
            <span
              class="text-responsive-24px font-pragmatica-extended lg:w-36rem"
            >
              {{ item.productData.name }}
            </span>
            <custom-number-input
              v-model.number="item.qty"
              class="mr-auto mt-10 md:mx-5 md:mt-0 lg:mx-10"
            />
          </div>

          <div
            v-for="(item, index) in items"
            :key="item.productData.slug"
            class="right-grid-section flex items-center justify-end relative bottom-horizontal-rule xl:justify-start"
            :class="`row-start-${index + 1} row-end-${index + 2}`"
          >
            <pre
              style="white-space: pre"
              class="text-responsive-24px font-pragmatica-extended text-left">{{
              $currency(item.prices.base.retailPrice)
            }} <br v-if="returnEuroPrice(item.prices.converted.row)"> <i v-if="returnEuroPrice(item.prices.converted.row)" class="text-ps not-italic font-bold">({{ returnEuroPrice(item.prices.converted.row) }})</i> </pre>

            <button
              type="button"
              class="absolute leading-none font-pragmatica-extended font-extrabold text-responsive-14px bottom-1rem lg:bottom-2.4rem focus:outline-none"
              @click="deleteCartItem(item)"
            >
              {{ $t('hsc.cart.remove') }}
            </button>
          </div>
          <div
            class="middle-grid-section text-responsive-20px bottom-horizontal-rule py-10 md:py-3.5rem"
          >
            <p class="mb-6">{{ $t('hsc.cart.subtotal') }}</p>

            <p v-if="false" class="md: my-3.5rem">{{ $t('hsc.cart.tax') }}</p>

            <p>{{ $t('hsc.cart.shipping') }}</p>
          </div>
          <div
            class="right-grid-section text-responsive-20px bottom-horizontal-rule py-10 flex flex-col items-end md:py-3.5rem xl:items-start"
          >
            <p class="font-pragmatica relative mb-6">
              {{ $currency(priceSummary.itemsTotal) }}
              <span
                v-if="returnEuroPrice(priceSummary.itemsTotal)"
                style="white-space: pre"
                class="absolute left-0 top-2.4rem text-ps font-bold"
                >({{ returnEuroPrice(priceSummary.itemsTotal) }})</span
              >
            </p>
            <p v-if="priceSummary.delivery" class="font-pragmatica">
              {{ $currency(priceSummary.delivery) }}
            </p>
            <span v-else class="font-pragmatica">{{
              $t('hsc.cart.free')
            }}</span>
          </div>

          <div
            class="pt-10 middle-grid-section text-responsive-24px font-extrabold font-pragmatica-extended"
          >
            <span>{{ $t('hsc.cart.total') }}</span>
          </div>

          <div
            class="pt-10 right-grid-section text-responsive-24px font-extrabold flex justify-end xl:justify-start"
          >
            <pre class="font-pragmatica-extended relative">{{
              $currency(priceSummary.cartTotal)
            }} <span v-if="returnEuroPrice(priceSummary.cartTotal)" style="white-space: pre" class="absolute left-0 top-2.4rem text-ps font-bold">({{ returnEuroPrice(priceSummary.cartTotal) }})</span></pre>
          </div>

          <div
            class="pt-16 col-span-full text-right font-extrabold font-pragmatica-extended lg:text-left lg:pt-24 lg:col-start-3 lg:col-end-11 xl:col-end-9"
          >
            <custom-form
              v-if="!cartState.couponCode"
              v-model="form.coupon"
              :submit-method="applyCoupon"
              :placeholder="$t('hsc.form.inputs.discountCode')"
              :btn-text="$t('hsc.form.actions.applyCode')"
              input-bg-color="whiteSmoke"
            />
            <form
              v-else
              class="inline-block text-responsive-14px"
              @submit.prevent="removeCoupon"
            >
              <div class="font-normal mb-4">
                {{ $t('hsc.cart.coupon') }} "{{ cartState.couponCode }}" -
                {{ $currency(priceSummary.coupon) }}
                <span
                  v-if="returnEuroPrice(priceSummary.coupon)"
                  class="text-ps font-bold"
                >
                  ({{ returnEuroPrice(priceSummary.coupon) }})
                </span>
              </div>

              <button
                type="submit"
                class="h-12 px-4 bg-darkGrayishViolet tracking-0.14rem uppercase text-white font-pragmatica-extended font-extrabold sm:h-16 sm:px-8 focus:outline-none"
              >
                {{ $t('hsc.cart.remove') + ' ' + $t('hsc.cart.coupon') }}
              </button>
            </form>
          </div>

          <div
            class="pt-10 col-span-full flex items-end justify-end text-responsive-14px font-extrabold font-pragmatica-extended lg:col-start-11 lg:col-end-13 xl:col-start-9 xl:col-end-11 lg:pt-0 xl:justify-start"
          >
            <cms-link :to="$Page.Checkout">
              <multiple-bordered-button
                primary-color-name="pureYellow"
                text-color-name="darkGrayishViolet"
              >
                <span class="btn-text">{{ $t('hsc.cart.checkout') }}</span>
              </multiple-bordered-button>
            </cms-link>
          </div>
        </div>
      </div>
    </controller-cart>
  </div>
</template>

<script>
import ControllerCart from 'Controllers/ControllerCart'
import CustomNumberInput from '@/components/ui/inputs/CustomNumberInput'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import CustomForm from '@/components/ui/forms/CustomForm'
import FaIcons from '@/mixins/FaIcons'
export default {
  components: {
    ControllerCart,
    CustomNumberInput,
    MultipleBorderedButton,
    CustomForm,
  },
  mixins: [FaIcons],
}
</script>
<style scoped>
a:hover svg {
  @apply text-pureYellow transform scale-110;
}
.text-responsive-24px,
.text-responsive-20px {
  @apply text-xl leading-none;
}

.text-responsive-14px {
  @apply text-lg leading-none;
}

.bottom-horizontal-rule {
  @apply border-b border-lightGrayishBlue;
}

.left-grid-section {
  @apply hidden;
}

.middle-grid-section {
  @apply col-start-1 col-end-9;
}

.right-grid-section {
  @apply col-start-9 col-end-13;
}

@media only screen and (min-width: 640px) {
  .text-responsive-24px {
    @apply text-2xl;
  }
}

@media only screen and (min-width: 768px) {
  .text-responsive-14px {
    @apply text-1.4rem;
  }
  .text-responsive-24px {
    @apply text-3xl;
  }
  .text-responsive-20px {
    @apply text-2xl;
  }
  .left-grid-section {
    @apply block col-start-1 col-end-3;
  }
  .middle-grid-section {
    @apply col-start-3 col-end-10;
  }
  .right-grid-section {
    @apply col-start-10 col-end-13;
  }
}

@media only screen and (min-width: 1024px) {
  .text-responsive-24px {
    @apply text-2.4rem;
  }
  .text-responsive-20px {
    @apply text-2rem;
  }
}

@media only screen and (min-width: 1280px) {
  .middle-grid-section {
    @apply col-start-3 col-end-9;
  }
  .right-grid-section {
    @apply col-start-9 col-end-11;
  }
}
</style>
