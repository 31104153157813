// Types
import mixins from"vue-typed-mixins";import{dummyProductRecord}from"../dummies/product";import ControllerProductProviderData from"../providerData/ControllerProductProviderData";// Mixins
import ControllerBase from"./ControllerBase";import BaseInjector from"./BaseInjector";import ProductListProvider from"./ProductListProvider";import ProductFilterFieldSelector from"./ProductFilterFieldSelector";const dummyInject=WithPartialInject("dummyMode"),pageCartInject=WithPartialInject("isCartView");//Utils
import{WithPartialInject}from"../utils/withInject";// If we use ControllerUpSell/CrossSell/RelatedProducts inside CartView or CartModalView,
// We need to read each cart item which is by default in CartItem type,
// then fetch its data in ProductRecord type so we can access upSell/crossSell/relatedProducts array,
// then we need to calculate computedIDs while taking care about duplicates.
// the goal is to return each upSelled/crossSelled/related product from each cart item if we are currently inside CartView or CartModalView
const ProductsFromIDs=mixins(ControllerBase,BaseInjector(ControllerProductProviderData),dummyInject,ProductListProvider,pageCartInject,ProductFilterFieldSelector).extend({data(){return{products:[],// Used only if isCartView is true
cartProductRecords:[]}},watch:{computedIDs:{async handler(){await this.$fetch()}}},computed:{computedIDs(){throw new Error("Nisi definirao computedIDs")},// Used only if isCartView is true
cartProductIDs(){return this.$cart.items.map(a=>a.productId)}},async fetch(){var a;this.isCartView&&!(null!==(a=this.productProvider)&&void 0!==a&&a.activeProduct)&&(await this.fetchProductRecordsFromCart()),await this.fetchProductsFromIDs()},methods:{clearProducts(){this.products.splice(0,this.products.length)},async fetchProductRecordsFromCart(){if(!this.cartProductIDs.length)return;const a=[{key:"id",value:this.cartProductIDs}],b=await this.$api.product.web.filter({filters:a,select:this.computedSelectParam});b.errored||this.cartProductRecords.splice(0,this.cartProductRecords.length,...b.data.records)},async fetchProductsFromIDs(){var a;if(this.dummyMode){//Creating dummy products
const a=Array.from([,,,,,,]).map(()=>dummyProductRecord),b=this.products;return void b.splice(0,b.length,...a)}if(!(null!==(a=this.computedIDs)&&void 0!==a&&a.length))return void this.clearProducts();const b=[{key:"id",value:this.computedIDs}],c=await this.$api.product.web.filter({filters:b,select:this.computedSelectParam});c.errored||this.products.splice(0,this.products.length,...c.data.records)}},render(){const{status:a}=this,b=this.products.map(b=>({item:b,status:a}));return this.renderContainer(b)}});export default ProductsFromIDs;