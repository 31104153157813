import {
  faFacebook,
  faInstagram,
  faYoutube,
  faReddit,
  faFacebookMessenger,
  faSkype,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export default {
  data() {
    return {
      icons: {
        social: {
          facebook: faFacebook,
          instagram: faInstagram,
          youtube: faYoutube,
          reddit: faReddit,
          messenger: faFacebookMessenger,
          skype: faSkype,
          twitter: faTwitter,
          whatsapp: faWhatsapp,
          email: 'envelope',
          sms: 'sms',
        },
        basic: {
          shoppingCart: 'shopping-cart',
          user: 'user',
          leftArrow: 'chevron-left',
          rightArrow: 'chevron-right',
          downArrow: 'chevron-down',
          close: 'times',
          share: 'share-alt',
          eye: faEye,
          eyeSlash: faEyeSlash,
        },
        pagination: {
          previousPage: 'angle-left',
          firstPage: 'angle-double-left',
          nextPage: 'angle-right',
          lastPage: 'angle-double-right',
        },
      },
    }
  },
}
