import Vue from"vue";// If received type of single Vue component
/**
 * Returns a fake Vue component with defined $refs variable. Used as a mixin
 * for typescript to provide type for `this.$refs`.
 * <br/>
 * Type parameter accepts object with variables of following types:
 * - `HTMLElement`
 * - `HTMLElement[]`
 * - `VueConstructor`
 * - `VueConstructor[]`
 * <br/>
 * ### Examples:
 * - HTML Element:
 * ```
 *    <div ref="someDiv"></div>
 *    tsx.componentFactory.mixin(WithRefs<{ someDiv: HTMLElement }>()).create({})
 * ```
 * - Vue Component:
 * ```
 *    import SomeComponent from './SomeWhere.vue'
 *    <SomeComponent ref="component" />
 *    tsx.componentFactory.mixin(WithRefs<{ component: typeof SomeComponent }>()).create({})
 * ```
 * - In loop:
 * ```
 *    <div v-for="n in 5" :key="n" ref="someDivs"></div>
 *    tsx.componentFactory.mixin(WithRefs<{ someDivs: HTMLElement[] }>()).create({})
 * ```
 */export const WithRefs=()=>Vue.extend();